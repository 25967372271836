import { Close, Info } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import CopyIcon from '@mui/icons-material/CopyAll';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, IconButton, Modal, Snackbar, Tooltip, Typography } from '@mui/material';
import Loader from 'components/elements/loading/Loader';
import Toast from 'components/toast';
import {
  GetAllSummariesByDateResponse,
  MeetingSummaryStage,
  NoteDetail,
  NoteExcerpt,
} from 'node-api/scribe/scribe';
import ScribeClient from 'node-api/scribe/scribe-client';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { copyToClipboard } from 'utils/clipboard';
import { getFormattedDateFromISOString } from 'utils/dateHelpers';
import '../../css/notes.css';
import { NoteEdition } from '../NoteEdition/NoteEdition';
import { renderSectionsByOutputType } from '../NoteEditionPreview/section';
import '../css/note-edition.css';
import '../css/note-preview.css';
import '../css/note.css';
import { prepareSectionsForCopying } from './format';

type Props = {
  noteExcerpt: NoteExcerpt;
  fetchSummaries: () => Promise<GetAllSummariesByDateResponse>;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
};

export const NotePreview = ({ noteExcerpt, fetchSummaries, setOpenModal }: Props) => {
  const [note, setNote] = useState<NoteDetail | null>(null);
  const [canEditNote, setCanEditNote] = useState<boolean>(false);
  const [openCopiedToClipboardMessage, setOpenCopiedToClipboardMessage] = useState<boolean>(false);
  const [openSignModal, setOpenSignModal] = useState<boolean>(false);

  const handleSignNote = async () => {
    const client = new ScribeClient();

    const response = await client.signSummary(noteExcerpt.meetingSummary.id);

    if (!response.status || !response.note?.meetingSummary) {
      Toast.show('error', 'There was an error updating note');
      return;
    }

    setNote({ ...(note as NoteDetail), meetingSummary: response.note.meetingSummary });

    Toast.show('success', 'Note was signed successfully');

    await fetchSummaries();

    setOpenModal(false);
  };

  const handleCopyNote = () => {
    copyToClipboard(note ? prepareSectionsForCopying(note) : '');
    setOpenCopiedToClipboardMessage(true);
  };

  const handleEditNote = () => setCanEditNote(!canEditNote);

  const handleSignModal = () => setOpenSignModal(!openSignModal);

  useEffect(() => {
    (async () => {
      const client = new ScribeClient();

      const response = await client.getSummary(noteExcerpt.meetingSummary.id);

      if (!response) {
        Toast.show('error', 'There was an error obtaining the note');
        return;
      }

      setNote(response);
    })();
  }, []);

  return note && canEditNote ? (
    <NoteEdition
      initialNote={note}
      fetchSummaries={fetchSummaries}
      setOpenModal={(v) => {
        setCanEditNote(!canEditNote);
        setOpenModal(v);
      }}
    />
  ) : (
    <Box className='note-preview-container'>
      {!note ? (
        <Loader
          style={{
            alignContent: 'center',
            justifyContent: 'center',
            margin: '25% 0',
          }}
        />
      ) : (
        <>
          <Box className='note-preview-header'>
            <Box display='flex' alignItems='center'>
              <Box className='note-list-row-icon' mr={1} />
              <Typography
                mr={1}
                sx={{
                  color: '#404040',
                  opacity: '60%',
                }}>{`${note.meetingType.internalCode} ${getFormattedDateFromISOString(
                note.meeting.createdAt,
                'h:mma'
              )}`}</Typography>
              <Typography
                color='#323648'
                mr={
                  1
                }>{`${note.provider.firstName} ${note.provider.lastName}, ${note.provider.specialty}`}</Typography>
            </Box>

            <IconButton size='small' onClick={() => setOpenModal(false)}>
              <Close />
            </IconButton>
          </Box>

          <Box className='note-preview-content-container'>
            <Box className='note-preview-content-header' mr={1}>
              <Typography
                component='h4'
                fontWeight={600}
                color='#353535'
                sx={{
                  fontSize: '18px !important',
                }}>
                Review Notes
              </Typography>
              <Box display='flex'>
                <Snackbar
                  message='Copied to clipboard'
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  autoHideDuration={2000}
                  onClose={() => setOpenCopiedToClipboardMessage(false)}
                  open={openCopiedToClipboardMessage}
                />

                <Modal open={openSignModal} className='note-modal'>
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    alignItems='center'
                    className='note-sign-modal'>
                    <Typography
                      textAlign='center'
                      mb={2}
                      fontWeight={600}
                      sx={{
                        fontSize: '18px',
                      }}>
                      Sign Medical Appointment Note
                    </Typography>
                    <Typography
                      textAlign='center'
                      mb={3}
                      sx={{
                        fontSize: '16px',
                      }}>
                      Please review and sign the note from the recent medical appointment
                    </Typography>
                    <Box display='flex' gap={2}>
                      <Button
                        fullWidth
                        className='note-sign-button'
                        variant='outlined'
                        sx={{
                          color: '#1a667a',
                        }}
                        onClick={handleSignModal}>
                        Dismiss
                      </Button>
                      <Button
                        fullWidth
                        className='note-sign-button'
                        variant='contained'
                        sx={{
                          backgroundColor: '#1a667a',
                        }}
                        onClick={handleSignNote}>
                        Sign Now
                      </Button>
                    </Box>
                  </Box>
                </Modal>

                <Box display='flex' alignItems='center' gap={1}>
                  {note.meetingSummary.stage === MeetingSummaryStage.PreVisit && (
                    <Tooltip title='The signature is only enabled after the medical initial'>
                      <Info
                        sx={{
                          color: '#1a667a',
                          '&.MuiSvgIcon-root': {
                            fontSize: '22px',
                          },
                        }}
                      />
                    </Tooltip>
                  )}

                  <Button
                    className='note-preview-action-buttons'
                    variant='text'
                    startIcon={<CheckIcon />}
                    onClick={handleSignModal}
                    disabled={note.meetingSummary.stage === MeetingSummaryStage.PreVisit}
                    sx={{
                      marginRight: '1em',
                    }}>
                    Sign
                  </Button>
                </Box>

                <Button
                  className='note-preview-action-buttons'
                  variant='text'
                  startIcon={<EditIcon />}
                  onClick={handleEditNote}
                  sx={{
                    marginRight: '1em',
                  }}>
                  Edit
                </Button>

                <Button
                  className='note-preview-action-buttons'
                  variant='text'
                  startIcon={<CopyIcon />}
                  onClick={handleCopyNote}
                  sx={{
                    marginRight: 0,
                  }}>
                  Copy
                </Button>
              </Box>
            </Box>

            {note.content
              ?.sort((a, b) => a.order - b.order)
              ?.map((section) => {
                return renderSectionsByOutputType(section);
              })}

            {note.meetingSummary?.signatureHistory?.map(({ signedBy, signedOn }) => {
              return (
                <Typography>{`Signed by ${signedBy.firstName} ${signedBy.lastName}, ${
                  signedBy.specialty
                } at ${getFormattedDateFromISOString(
                  signedOn,
                  'MM/DD/YYYY h:mm A z'
                )}`}</Typography>
              );
            })}
          </Box>
        </>
      )}
    </Box>
  );
};
