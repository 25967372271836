import { FeaturesApiClient } from '@enara-modules/enara-module-features-api-client';
import api from 'api';
import EnaraLoader from 'components/elements/loaders/enara-loader/EnaraLoader';
import Toast from 'components/toast';
import { FEATURE } from 'config';
import { DEFAULT_FEATURES, EFeatures } from 'lib/feature/features.types';
import node_api from 'node-api';
import { ReviewListClient } from 'node-api/reviewList/reviewlistClient';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useState } from 'react';
import AuthService from 'services/auth-service';
import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { ClinicContext } from 'state/contexts/clinic';
import { FeaturesContext } from 'state/contexts/features';
import { ProgramContext } from 'state/contexts/program';
import { ProvidersContext } from 'state/contexts/providers';
import { ReviewLists4Context } from 'state/contexts/reviewLists4';
import { StatusContext } from 'state/contexts/status';
import { TagContext } from 'state/contexts/tag';
import { UserContext } from 'state/contexts/user';
import { ActionTypes as FeaturesActionTypes } from 'state/reducers/features';
import { ActionTypes } from 'state/reducers/providers';
import { ActionTypes as UserActionTypes } from 'state/reducers/user';
import { getOriginalUserUUID, getProviderClinicIds } from 'utils/helpers';
import { FullPageWrapperForLoader, LoaderContainer } from './style';

const Authentication = ({ history, children }) => {
  const { userState, userDispatch } = useContext(UserContext);
  const { programDispatch } = useContext(ProgramContext);
  const { statusDispatch } = useContext(StatusContext);
  const { tagDispatch } = useContext(TagContext);
  const { clinicDispatch } = useContext(ClinicContext);
  const { providersDispatch } = useContext(ProvidersContext);
  const { featuresDispatch } = useContext(FeaturesContext);
  const { reviewLists4Dispatch } = useContext(ReviewLists4Context);

  const [loading, setLoading] = useState(true);
  const isLogged = !!AuthService.getAuth();

  const logoutProviderAndReportError = useCallback(
    (error) => {
      AuthService.removeAuth();
      history.push('/login');
      Toast.show('error', error);
    },
    [history]
  );

  const getFeatures = useCallback(
    async (clinics) => {
      const featuresClient = new FeaturesApiClient({
        targetEnv: FEATURE.targetEnv,
        baseUrl: FEATURE.url,
      });

      let featuresResponse = null;

      try {
        featuresResponse = await featuresClient.getFeatures({
          useRawValues: true,
          featureNames: Object.values(EFeatures),
        });
      } catch (err) {
        console.error('Error getting feature flags. Using defaults');
      }

      const featuresToDispatch = DEFAULT_FEATURES;
      if (clinics && featuresResponse) {
        const providerClinicIds = getProviderClinicIds(clinics);
        Object.entries(featuresResponse.values).forEach(([key, { enabled, clinicIds }]) => {
          featuresToDispatch[key] =
            enabled &&
            (!clinicIds || clinicIds.some((clinicId) => providerClinicIds.has(clinicId)));
        });
      }

      featuresDispatch({
        type: FeaturesActionTypes.SET,
        payload: featuresToDispatch,
      });

      return featuresToDispatch;
    },
    [featuresDispatch]
  );

  const fetchUserData = useCallback(async () => {
    const originalUserUUID = getOriginalUserUUID();
    const isOriginalUser = !originalUserUUID || originalUserUUID === 'undefined';
    if (originalUserUUID === 'undefined') {
      localStorage.removeItem('Origu');
    }

    try {
      const {
        data: { user },
      } = await api().get('users/AUTH_ID', {
        params:
          originalUserUUID === null || originalUserUUID === 'undefined'
            ? {
                auth_token: 'AUTH_TOKEN',
              }
            : {
                auth_token: 'AUTH_TOKEN',
                original_user_uuid: originalUserUUID,
              },
      });

      const userDataToDispatch = { ...user, isOriginalUser };

      userDispatch({
        type: UserActionTypes.SET,
        payload: userDataToDispatch,
      });
      return userDataToDispatch;
    } catch (err) {
      console.error(err);
      logoutProviderAndReportError("Couldn't get user profile");
    }
  }, [logoutProviderAndReportError, userDispatch]);

  const fetchUserPrograms = useCallback(async () => {
    try {
      const {
        data: { data: programs },
      } = await api().get('user_programs/AUTH_TOKEN');

      programDispatch({
        type: 'SET',
        payload: programs,
      });
      return programs;
    } catch (err) {
      console.error(err);
      logoutProviderAndReportError("Couldn't get user programs");
    }
  }, [logoutProviderAndReportError, programDispatch]);

  const fetchUserStatuses = useCallback(async () => {
    try {
      const {
        data: { data: statuses },
      } = await api().get('profile_statuses?auth_token=AUTH_TOKEN');

      statusDispatch({
        type: 'SET',
        payload: statuses,
      });
      return statuses;
    } catch (err) {
      console.error(err);
      logoutProviderAndReportError("Couldn't get user statuses");
    }
  }, [logoutProviderAndReportError, statusDispatch]);

  const fetchUserTags = useCallback(async () => {
    try {
      const {
        data: { data: tags },
      } = await api().get('patient_tags?auth_token=AUTH_TOKEN');
      tagDispatch({
        type: 'SET',
        payload: tags,
      });
      return tags;
    } catch (err) {
      console.error(err);
      logoutProviderAndReportError("Couldn't not get user tags");
    }
  }, [logoutProviderAndReportError, tagDispatch]);

  const fetchAllProviders = useCallback(async () => {
    try {
      const {
        data: { data: providers },
      } = await api().get('providers/AUTH_TOKEN');

      providersDispatch({
        type: ActionTypes.SET,
        payload: {
          data: providers,
        },
      });
    } catch (err) {
      console.error(err);
      logoutProviderAndReportError("Couldn't get providers");
    }
  }, [logoutProviderAndReportError, providersDispatch]);

  const fetchAssignedReviewLists = useCallback(async () => {
    try {
      const data = await new ReviewListClient().getReviewLists4();
      if (data.success) {
        reviewLists4Dispatch({
          type: 'SET',
          payload: data.reviewLists,
        });
        return data.reviewLists;
      }
    } catch (err) {
      console.error(err);
      Toast.show('error', "Couldn't get assigned review lists");
    }
  }, [reviewLists4Dispatch]);

  const fetchClinics = useCallback(async () => {
    try {
      const {
        data: { data: clinicsAndRoles },
      } = await node_api().get('clinics', {
        params: {
          auth_token: 'AUTH_TOKEN',
        },
      });

      const clinicsAssignedToProvider = clinicsAndRoles.clinics.filter((clinic) => {
        return clinic.assigned;
      });

      clinicDispatch({
        type: 'SET',
        payload: { ...clinicsAndRoles, clinicsAssignedToProvider },
      });
      return clinicsAndRoles;
    } catch (err) {
      console.error(err);
      logoutProviderAndReportError("Couldn't get clinics");
    }
  }, [logoutProviderAndReportError, clinicDispatch]);

  const getInitialData = useCallback(async () => {
    const clinics = await fetchClinics();
    // if clinics === undefined then getFeatures sets default feature flags
    await getFeatures(clinics);
    setLoading(false);
  }, [fetchClinics, getFeatures]);

  useEffect(() => {
    if (!!isLogged) {
      getInitialData();
    } else {
      history.push('/login');
    }
  }, [getInitialData, history, isLogged]);

  useEffect(() => {
    if (userState) {
      MixpanelWrapperInstance.configureMixpanel(userState);
    }
  }, [userState]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  useEffect(() => {
    fetchUserPrograms();
  }, [fetchUserPrograms]);

  useEffect(() => {
    fetchUserStatuses();
  }, [fetchUserStatuses]);

  useEffect(() => {
    fetchUserTags();
  }, [fetchUserTags]);

  useEffect(() => {
    fetchAllProviders();
  }, [fetchAllProviders]);

  useEffect(() => {
    fetchAssignedReviewLists();
  }, [fetchAssignedReviewLists]);

  if (loading) {
    return (
      <FullPageWrapperForLoader>
        <LoaderContainer>
          <EnaraLoader />
        </LoaderContainer>
      </FullPageWrapperForLoader>
    );
  }

  return children;
};

Authentication.propTypes = {
  history: PropTypes.shape().isRequired,
  children: PropTypes.PropTypes.shape().isRequired,
};

export default Authentication;
