/* eslint-disable */
import api from 'api';
import axios from 'axios';
import Spinner from 'components/elements/loaders/spinner';
import { SectionMessage } from 'components/general-styles';
import { debounce } from 'lodash';
import moment from 'moment-timezone';
import { ProviderEvent, ProviderTimeTrackerClient } from 'node-api/ProviderTimeTrackerClient';
import { AttentionDimension } from 'node-api/attention/AttentionApi.types';
import { AttentionClient } from 'node-api/attention/AttentionApiClient';
import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { EventNames } from 'services/mixpanel/provider-app-mixpanel-events';
import { ChatContext } from 'state/contexts/chat';
import { PatientContext } from 'state/contexts/patient';
import { StickyContext } from 'state/contexts/sticky';
import { TaskContext } from 'state/contexts/task';
import { UserContext } from 'state/contexts/user';
import { ActionTypes as ChatActionTypes } from 'state/reducers/chat';
import { ActionTypes as PatientActionTypes } from 'state/reducers/patient';
import { ActionTypes as TaskActionTypes } from 'state/reducers/task';
import { DIRECT_ROOM, TEAM_ROOM } from 'utils/constants';
import { debug } from 'utils/helpers';
import { DEFAULT_TIMEZONE } from 'utils/timezones';
import Switch from '../../../../elements/switch';
import MessageList from '../list/MessageList';
import MessageInput from '../message-input/MessageInput';
import { ChatBox, CheckInput, CheckSelector, Container } from './style';

const Room = forwardRef((props, ref) => {
  const { chatState, chatDispatch } = useContext(ChatContext);
  const { userState } = useContext(UserContext);
  const { patientState, patientDispatch } = useContext(PatientContext);
  const { taskDispatch } = useContext(TaskContext);
  const { stickyDispatch } = useContext(StickyContext);

  const patient = patientState.current;
  const [height, setHeight] = useState();
  const [scrolled, setScrolled] = useState();
  const [messages, setMessages] = useState([]);
  const [acknowledgeMessage, setAcknowledgeMessage] = useState({});
  const [selected, setSelected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hideBadge, setHideBadge] = useState(false);
  const [showMarkAsReadSwitch, setShowMarkAsReadSwitch] = useState(false);

  const [prevId, setPrevId] = useState(0);
  const prevIdRef = useRef(prevId);
  const [nextId, setNextId] = useState(0);
  const nextIdRef = useRef(nextId);

  const chatBoxRef = useRef(null);

  const removeBadgeFromTab = () => {
    try {
      if (props.room.name === DIRECT_ROOM) {
        props.setNewDirectMsg(false);
      } else {
        props.setNewTeamMsg(false);
      }
    } catch (e) {}
  };

  useImperativeHandle(ref, () => ({
    handleMessageReceived({ sender_uuid, patient_uuid, room_name }) {
      if (room_name === props.room.name) {
        debug(`ROOM-${room_name}-received------${sender_uuid}-${patient_uuid}`);

        if (room_name === DIRECT_ROOM) {
          if (sender_uuid === patient.uuid) {
            MixpanelWrapperInstance.track(EventNames.ChatReceiveMessage, {
              Source: 'Patient',
            });
            setShowMarkAsReadSwitch(true);
            setSelected(false);
            setHideBadge(false);
            getMessages('next');
          }
        }

        if (room_name === TEAM_ROOM) {
          if (sender_uuid !== userState.uuid && patient_uuid === patient.uuid) {
            MixpanelWrapperInstance.track(EventNames.ChatReceiveMessage, {
              Source: 'Team',
            });

            setShowMarkAsReadSwitch(true);
            setSelected(false);
            setHideBadge(false);
            getMessages('next');
          }
        }
      }
    },
  }));

  const getFetchUrl = (type, direction = 'next') => {
    let url = '';
    let params = '';

    switch (type) {
      case DIRECT_ROOM:
        url = `messages/chat/${patient.uuid}/AUTH_TOKEN`;
        params =
          direction === 'next'
            ? `?last_message_id=${nextIdRef.current}`
            : `?first_message_id=${prevIdRef.current}`;
        break;

      case TEAM_ROOM:
        url = `messages/provider_messages/${patient.uuid}/AUTH_TOKEN`;
        break;
    }

    return `${url}${params}`;
  };

  const getSubmitUrl = (type) => {
    let url = '';

    if (type === DIRECT_ROOM) {
      url = `messages?chat_id=${patient.uuid}&auth_token=AUTH_TOKEN`;
    }

    if (type === TEAM_ROOM) {
      url = `/messages/provider_messages/${patient.uuid}/AUTH_TOKEN`;
    }

    return `${url}`;
  };

  const switchOnChange = async () => {
    if (selected === false) {
      setSelected(!selected);
      try {
        removeBadgeFromTab();
        handleMarkAsTendedTo();

        const endpoint_name =
          props.room.name === DIRECT_ROOM
            ? 'provider_acknowledgements'
            : 'provider_team_chat_acknowledgements';
        const { data } = await api().post(
          `providers/${userState.uuid}/${endpoint_name}?auth_token=AUTH_TOKEN`,
          {
            auth_token: 'AUTH_TOKEN',
            patient_id: patient.id,
          }
        );
        if (data.success === 'true') {
          setShowMarkAsReadSwitch(false);
          setHideBadge(true);
        } else {
          setSelected(false);
        }
      } catch (err) {
        debug(err);
        setSelected(false);
      }
    }
  };

  const getMessages = async (direction = 'next') => {
    if (direction === 'prev' && prevIdRef.current === 0) return false;

    try {
      if (direction === 'prev') setLoading(true);

      const { data } = await api().get(getFetchUrl(props.room.name, direction));
      let newMessages = props.room.name === DIRECT_ROOM ? data?.messages || [] : data.data || [];

      const acknowledgeMsg = data.provider_acknowledgement || {};

      if (newMessages.length > 0) {
        if (props.room.name === DIRECT_ROOM) {
          if (direction === 'next') setNextId(newMessages[0].id);
          if (direction === 'prev') setPrevId(data.prev_message_id);
          newMessages.reverse();
        }

        if (props.room.name === TEAM_ROOM) {
          newMessages = newMessages.filter((m) => m.id > nextIdRef.current);
          setNextId(newMessages[newMessages.length - 1].id);
        }
      }

      chatDispatch({
        type: direction === 'next' ? ChatActionTypes.ADD : ChatActionTypes.PREPEND,
        payload: {
          direction,
          id: props.patientId,
          type: props.room.name,
          data: newMessages,
          acknowledgeMessage: acknowledgeMsg,
        },
      });
    } catch (err) {
      debug(err);
    }

    if (direction === 'prev') setLoading(false);
  };

  const handleMarkAsTendedTo = async () => {
    const attentionClient = new AttentionClient();
    if (props.room.name === DIRECT_ROOM) {
      attentionClient.markMemberAsTendedToForDimension({
        memberIds: [Number(props.patientId)],
        dimensionKey: AttentionDimension.MemberChat,
      });
    } else {
      attentionClient.markMemberAsTendedToForDimension({
        memberIds: [Number(props.patientId)],
        dimensionKey: AttentionDimension.TeamChat,
      });
    }
  };

  const submitMessage = async (message) => {
    try {
      removeBadgeFromTab();

      const provider_ids = [];
      const splitted_message = message.split(/\(\((.*?)\)\)/g);

      let content = splitted_message.reduce((acc, cur) => {
        const string = cur.replace(/]]/g, '').replace(/\[\[/g, '');

        if (!string.startsWith('id:')) {
          return acc + string;
        }

        provider_ids.push(parseInt(string.replace('id:', ''), 10));
        return acc;
      }, '');

      if (content.includes('#task')) {
        taskDispatch({ type: TaskActionTypes.Reload });
      }

      if (content.includes('#stick')) {
        stickyDispatch({ type: 'RELOAD' });
      }

      content = content.replace(/ {2,}/g, ' ').trim();

      const original_user =
        (localStorage.getItem('Origu') && localStorage.getItem('Origu').split('|')[1]) || null;

      const { data } = await api().post(getSubmitUrl(props.room.name), {
        content,
        provider_ids,
        ...(userState.isOriginalUser === false && original_user !== null && { original_user }),
      });

      handleMarkAsTendedTo();

      const trackableEvent = {
        event:
          props.room.name === DIRECT_ROOM
            ? ProviderEvent.ProviderSendPrivateMessageToMember
            : ProviderEvent.ProviderSendPrivateMessageToProvider,
        patientUuid: patient.uuid,
      };

      new ProviderTimeTrackerClient().track(trackableEvent);

      setNextId(data.message_id);
      chatDispatch({
        type: ChatActionTypes.ADD,
        payload: {
          direction: 'next',
          id: props.patientId,
          type: props.room.name,
          data: [
            {
              content,
              created_at: moment.tz(Date.now(), DEFAULT_TIMEZONE).format('YYYY-MM-DD HH:mm:ss Z'),
              date: moment.tz(Date.now(), DEFAULT_TIMEZONE).format('YYYY-MM-DD HH:mm:ss Z'),
              id: data.message_id,
              sender_avatar_url: userState.avatar,
              sender_name: `${
                userState.isOriginalUser === false && original_user !== null
                  ? `${original_user} as `
                  : ''
              }${userState.first_name} ${userState.last_name}`,
              side: 'right',
            },
          ],
        },
      });
      patientDispatch({
        type: PatientActionTypes.CLEAR_MESSAGE,
        payload: { memberId: patient.id },
      });

      setShowMarkAsReadSwitch(false);
      setHideBadge(true);
    } catch (err) {
      debug(err);
    }
  };

  const getScrollPosition = ({ target }) => {
    if (!scrolled) {
      setScrolled(true);
    }
    if (target.scrollTop === 0) {
      getMessages('prev');
    }
  };

  useEffect(() => {
    prevIdRef.current = prevId;
    nextIdRef.current = nextId;
  }, [prevId, nextId]);

  useEffect(() => {
    if (chatState.data[props.room.name][props.patientId]) {
      const newMessages = chatState.data[props.room.name][props.patientId].messages;
      setMessages(newMessages || []);

      const acknowledgeMsg = chatState.data[props.room.name][props.patientId].acknowledgeMessage;
      setAcknowledgeMessage(acknowledgeMsg);
    }
  }, [chatState.data[props.room.name][props.patientId]]);

  useEffect(() => {
    const { direction } = chatState;

    const newHeight = chatBoxRef.current.scrollHeight;
    chatBoxRef.current.scrollTop = direction === 'next' ? newHeight : newHeight - height;

    setHeight(newHeight);
  }, [messages]);

  useEffect(() => {
    setLoading(true);
    const source = axios.CancelToken.source();

    const getData = async () => {
      try {
        const { data } = await api().get(getFetchUrl(props.room.name));
        const newMessages =
          props.room.name === DIRECT_ROOM ? data?.messages || [] : data.data || [];

        const acknowledgeMsg = data.provider_acknowledgement || {};

        if (newMessages.length > 0) {
          if (props.room.name === DIRECT_ROOM) {
            setNextId(newMessages[0].id);
            setPrevId(data.prev_message_id);
            newMessages.reverse();
          }

          if (props.room.name === TEAM_ROOM) {
            setNextId(newMessages[newMessages.length - 1].id);
          }
        }

        chatDispatch({
          type: ChatActionTypes.SET,
          payload: {
            id: props.patientId,
            type: props.room.name,
            data: newMessages,
            acknowledgeMessage: acknowledgeMsg,
          },
        });

        setLoading(false);
      } catch (err) {
        if (!axios.isCancel(err)) {
          debug(err);
          setLoading(false);
        }
      }
    };

    getData();

    return () => {
      setMessages([]);
      source.cancel();
    };
  }, [props.patientId]);

  useEffect(() => {
    chatBoxRef.current.addEventListener('scroll', debounce(getScrollPosition, 50));

    return () => {
      if (chatBoxRef.current) {
        chatBoxRef.current.removeEventListener('scroll', debounce(getScrollPosition, 50));
      }
    };
  }, []);

  return (
    <Container>
      <ChatBox ref={chatBoxRef}>
        {loading && (
          <SectionMessage>
            <p>Loading messages</p>
            <Spinner size={24} />
          </SectionMessage>
        )}

        {!loading && messages.length === 0 && (
          <SectionMessage>
            <p>No messages</p>
          </SectionMessage>
        )}

        {!loading && messages.length > 0 && (
          <MessageList
            messages={messages}
            acknowledgeMessage={acknowledgeMessage}
            type={props.room.name}
            hideBadge={hideBadge}
            showSwitch={setShowMarkAsReadSwitch}
          />
        )}
      </ChatBox>
      {showMarkAsReadSwitch === true && (
        <CheckSelector>
          <Switch checked={selected === true} onChange={switchOnChange} value={selected} />
          <CheckInput>Mark As Read</CheckInput>
        </CheckSelector>
      )}
      <MessageInput type={props.room.name} patient={patient} onClick={submitMessage} autoFocus />
    </Container>
  );
});

export default Room;
