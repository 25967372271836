import { Avatar, Link } from '@mui/material';
import { Button } from 'components/elements';
import { Constants } from 'components/main/calendar/utils';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { ScheduledAppointment } from 'node-api/schedule/AppointmentsClient';
import { schedulingV3Client } from 'node-api/scheduleV3/client.v3';
import { FC, useContext, useState } from 'react';
import { EventNames } from 'services/mixpanel/provider-app-mixpanel-events';

import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { ClickableElement } from 'services/mixpanel/shared-mixpanel-service';
import { UserContext } from 'state/contexts/user';
import { debug } from 'utils/helpers';
import { getMemberAvatarUrl, getMemberProfileUrl } from 'utils/members';
import {
  formatAppointmentStartEnd,
  getAppointmentSimplifiedName,
  getAppointmentTypeCodes,
} from 'utils/schedule';
import './SchedulePreviewCard.css';

type Props = {
  appointment: ScheduledAppointment;
  timezone: string;
};

export const SchedulePreviewCard: FC<Props> = ({ appointment, timezone }) => {
  const [isJoinCallButtonLoading, setIsJoinCallButtonLoading] = useState(false);
  const { userState } = useContext(UserContext);

  const handleJoinCall = async () => {
    MixpanelWrapperInstance.track(EventNames.MemberCallJoin, {
      targetLabel: 'Join Call',
      targetType: ClickableElement.BUTTON,
      targetLocation: 'Home',
      memberUuid: appointment.user.uuid,
      memberName: `${appointment.user.firstName} ${appointment.user.lastName}`,
      providerUuid: userState.uuid,
      source: 'weekly-view-preview-card',
    });

    setIsJoinCallButtonLoading(true);

    try {
      const url = await schedulingV3Client.getProviderVideoUrl(
        appointment.user,
        {
          id: userState.id ?? -1,
          uuid: userState.uuid ?? '',
        },
        'provider',
        'weekly-view-preview-card'
      );

      window.open(url, '_blank');
    } catch (err) {
      debug(`${err}`);
    }

    setIsJoinCallButtonLoading(false);
  };

  const trackGoToMemberProfile = () => {
    MixpanelWrapperInstance.track(EventNames.MemberProfileOpen, {
      targetLabel: 'View Profile',
      targetType: ClickableElement.LINK,
      'member-id': appointment.user.uuid,
      memberName: `${appointment.user.firstName} ${appointment.user.lastName}`,
      source: 'weekly-view-preview-card',
    });
  };

  const [, , appointmentLocation] = getAppointmentTypeCodes(appointment.appointmentType);

  // Can only join call if appointment will start between 15 minutes before and 15 minutes after the current time
  const isAppointmentSoon = moment().isBetween(
    moment(appointment.startAt).subtract(15, 'minutes'),
    moment(appointment.startAt).add(15, 'minutes')
  );
  const appointmentTimeLabel: 'Past' | 'Current' | 'Next' | 'Future' = moment().isAfter(
    appointment.startAt
  )
    ? isAppointmentSoon
      ? 'Current'
      : 'Past'
    : moment().isSame(appointment.startAt, 'day')
    ? 'Next'
    : 'Future';

  const canJoin = appointmentLocation === 'OL' && isAppointmentSoon;

  const memberFullName = [appointment.user.firstName, appointment.user.lastName].join(' ');
  const memberTimezone = moment()
    .tz(appointment.user.timezone)
    .format(Constants.TIMEZONE_ABBREVIATED_FORMAT);

  const dayAsText = momentTimezone(appointment.startAt).tz(timezone).format('MMMM, dddd Do');
  const timesAsText = formatAppointmentStartEnd(appointment, timezone);
  const avatarUrl = getMemberAvatarUrl(appointment.user);

  return (
    <div className='schedulePreviewCard-container'>
      <div className='appointment-info'>
        <div className='info'>
          <p className='title'>{appointmentTimeLabel} Appointment</p>
          <p className='appointment-type'>
            {getAppointmentSimplifiedName(appointment.appointmentType)}
          </p>
          <p className='appointment-date'>{dayAsText}</p>
          <p className='appointment-timezone'>{timesAsText}</p>
        </div>
        <div className='link'>
          <Button onClick={handleJoinCall} submitting={isJoinCallButtonLoading} disabled={!canJoin}>
            {appointmentLocation === 'OL' ? 'Join Call' : 'In person'}
          </Button>
        </div>
      </div>
      <hr />
      <div className='member-info'>
        <Avatar alt={memberFullName} src={avatarUrl.thumb.url} sx={{ width: 70, height: 70 }} />
        <div className='info'>
          <p className='member-name'>{memberFullName}</p>
          <p className='member-phone'>Phone: {appointment.user.phone}</p>
          <p className='member-timezone'>Member Timezone: {memberTimezone}</p>
        </div>
        <div className='link'>
          <Link
            href={getMemberProfileUrl(appointment.user)}
            onClick={trackGoToMemberProfile}
            target='_blank'
            style={{ whiteSpace: 'nowrap', textDecoration: 'underline' }}>
            View Profile
          </Link>
        </div>
      </div>
    </div>
  );
};
