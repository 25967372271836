import { Dispatch, FC, PropsWithChildren, createContext, useReducer } from 'react';
import { Action, State, initialState, reducer } from '../reducers/patient';

export type PatientContextValue = {
  patientState: State;
  patientDispatch: Dispatch<Action>;
};

const PatientContext = createContext<PatientContextValue>({
  patientState: initialState,
  patientDispatch: () => null,
});

const PatientProvider: FC<PropsWithChildren> = ({ children }) => {
  const [patientState, patientDispatch] = useReducer(reducer, initialState);

  return (
    <PatientContext.Provider value={{ patientState, patientDispatch }}>
      {children}
    </PatientContext.Provider>
  );
};

export { PatientContext, PatientProvider };
