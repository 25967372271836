import { createContext, Dispatch, FC, PropsWithChildren, useReducer } from 'react';
import { Provider } from 'state/reducers/provider';
import { Action, initialState, reducer, State } from '../reducers/providers';

export type ProvidersContextValue = {
  providersState: State;
  providersDispatch: Dispatch<Action>;
  getProviderById: (providerId: number | undefined) => Provider | undefined;
};

const ProvidersContext = createContext<ProvidersContextValue>({
  providersState: initialState,
  providersDispatch: () => null,
  getProviderById: () => undefined,
});

const ProvidersProvider: FC<PropsWithChildren> = ({ children }) => {
  const [providersState, providersDispatch] = useReducer(reducer, initialState);

  function getProviderById(providerId: number | undefined): Provider | undefined {
    if (!providerId) {
      return undefined;
    }

    return providersState.data.find((p) => p.id === providerId);
  }

  return (
    <ProvidersContext.Provider value={{ providersState, providersDispatch, getProviderById }}>
      {children}
    </ProvidersContext.Provider>
  );
};

export { ProvidersContext, ProvidersProvider };
