/* eslint-disable */
import api from 'api';
import axios from 'axios';
import { ClickableImage, Loading } from 'components/elements';
import ImageToggleOnMouseOver from 'components/elements/image-toggle';
import Icon from 'components/icon';
import { EFeatures } from 'lib/feature/features.types';
import moment from 'moment';
import { ProviderEvent, ProviderTimeTrackerClient } from 'node-api/ProviderTimeTrackerClient';
import { AttentionDimension } from 'node-api/attention/AttentionApi.types';
import { AttentionClient } from 'node-api/attention/AttentionApiClient';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { TrackableElement } from 'services/mixpanel/mixpanel-service';
import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { ClickElementTypes, EventHandlerNames } from 'services/mixpanel/mixpanel.types';
import { FeaturesContext } from 'state/contexts/features';
import { PatientContext } from 'state/contexts/patient';
import { IMAGE_IDENTIFIER } from 'utils/constants';
import { debug } from 'utils/helpers';
import arrowLeftHover from '../../../icon/pngs/arrow-left-hover.png';
import arrowLeft from '../../../icon/pngs/arrow-left.png';
import arrowRightHover from '../../../icon/pngs/arrow-right-hover.png';
import arrowRight from '../../../icon/pngs/arrow-right.png';
import { SpanLabel } from '../../metrics/style';
import Modal from '../modal';
import {
  AutoComment,
  Avatar,
  Body,
  Button,
  Comment,
  Comments,
  Container,
  Footer,
  Header,
  Image,
  Input,
  LabelSpan,
  LeftArrow,
  Line,
  PostComment,
  PostCommentHeader,
  PredefinedMessage,
  PredefinedMessages,
  RightArrow,
  SectionDetails,
  SectionImage,
  SubmitButton,
} from './style';

const MODAL_NAME = 'Food Pictures Modal';
// TODO: store these predefined messages in our db (v2)
const PREDEFINED_MESSAGES = [
  "Let's try adding more vegetables here next time!",
  'Looks great! make sure to include protein for extra satiety.',
  'Love the balance on your plate!',
  'Great choice!',
  'This looks great! Portions look good.',
  'Thanks for logging!',
  'Did this meal keep you full?',
];

const ViewFood = ({ onClose, element_id: id, elements: foods, patientId, callback }) => {
  const { patientState } = useContext(PatientContext);

  const patient = patientState.current;
  const [selectedIndex, setSelectedIndex] = useState(foods.findIndex((x) => x.id === id));

  const [composedComment, setComposedComment] = useState('');
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(false);
  const [commentModal, setCommentModal] = useState(false);

  const [autoNext, setAutoNext] = useState(false);

  const { featuresState } = useContext(FeaturesContext);
  const canMarkAsReviewed = featuresState?.[EFeatures.ProviderProfileShowNeedsAttention];

  const wrapperRef = useRef(null);

  const handleIconClick = () => {
    setStatus(!status);
    status ? setCommentModal(false) : setCommentModal(true);
  };

  const handleCommentClick = (value) => {
    setComposedComment(value);
    setCommentModal(false);
    setStatus(false);
  };

  const getComments = async (source) => {
    try {
      // const { data } = await api().get(`foods/${selectedID}/comments/AUTH_TOKEN`, {
      const { data } = await api().get(`foods/${foods[selectedIndex].id}/comments/AUTH_TOKEN`, {
        cancelToken: source.token,
      });
      typeof data.comments === 'undefined' ? setComments([]) : setComments(data.comments);
      setLoading(false);
      // Marking current picture's comments as reviewed
      if (typeof callback === 'function') {
        callback(foods[selectedIndex].id);
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        debug(err);
      }
    }
  };

  const refreshComments = () => {
    setLoading(true);
    const source = axios.CancelToken.source();
    getComments(source);
    setComposedComment('');

    return source.cancel;
  };

  const isLastPicture = foods.length === selectedIndex + 1;
  const stayActionLabel =
    !canMarkAsReviewed || composedComment?.length > 0 ? 'Send' : 'Mark as Reviewed';
  const actionEnabled = canMarkAsReviewed || composedComment?.length > 0;

  const nextActionLabel = stayActionLabel + (isLastPicture ? ' & Close' : ' & Next');
  const currentActionLabel = autoNext ? nextActionLabel : stayActionLabel;

  const markAsReviewed = async () => {
    try {
      const attentionClient = new AttentionClient();
      attentionClient.markMemberAsTendedToForDimension({
        memberIds: [Number(patientId)],
        dimensionKey: AttentionDimension.FoodComments,
      });
      attentionClient.markMemberAsTendedToForDimension({
        memberIds: [Number(patientId)],
        dimensionKey: AttentionDimension.Food,
      });
    } catch (err) {
      debug(err);
    }
  };

  const handleSubmitComment = async (e) => {
    e.preventDefault();

    try {
      if (composedComment) {
        await api().post(`foods/${foods[selectedIndex].id}/comments`, {
          auth_token: 'AUTH_TOKEN',
          content: composedComment,
        });

        MixpanelWrapperInstance.trackClick({
          name: 'Send Comment',
          type: ClickElementTypes.BUTTON,
          source: MODAL_NAME + ' - ' + currentActionLabel,
          inputData: {
            comment: composedComment,
          },
        });
      } else {
        MixpanelWrapperInstance.trackClick({
          name: 'Review Comment',
          type: ClickElementTypes.BUTTON,
          source: MODAL_NAME + ' - ' + currentActionLabel,
          inputData: {
            comment: composedComment,
          },
        });
      }

      markAsReviewed();

      if (autoNext) {
        if (isLastPicture) {
          onClose();
        } else {
          nextPicture(+1, foods);
        }
      } else {
        refreshComments();
      }
    } catch (err) {
      debug(err);
    }

    try {
      await new ProviderTimeTrackerClient().track(
        {
          event: ProviderEvent.ProviderSendFoodComment,
          patientUuid: patient.uuid,
        },
        foods[selectedIndex].id
      );
    } catch (err) {
      debug(err);
    }
  };

  useEffect(() => {
    const cancel = refreshComments();

    return () => {
      cancel();
    };
  }, [foods[selectedIndex].id]);

  const commenting = () => {
    if (commentModal) {
      return (
        <AutoComment ref={wrapperRef}>
          <p>Send Auto Comment</p>
          <PredefinedMessages>
            {PREDEFINED_MESSAGES.map((message, index) => (
              <PredefinedMessage
                data-tip={message}
                data-for={`pre-defined-message-${index}`}
                key={`pre-defined-message-${index}`}>
                <ReactTooltip
                  id={`pre-defined-message-${index}`}
                  place='top'
                  type='dark'
                  effect='solid'
                />
                <TrackableElement
                  customEventName='Clicked Predefined Message'
                  name={message}
                  type={ClickElementTypes.BUTTON}
                  source={MODAL_NAME}
                  eventHandlerName={EventHandlerNames.ONCLICK}>
                  <button onClick={() => handleCommentClick(message)}>{message}</button>
                </TrackableElement>
              </PredefinedMessage>
            ))}
          </PredefinedMessages>
        </AutoComment>
      );
    }
  };

  const useOutsideAlerter = (ref) => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setStatus(false);
        setCommentModal(false);
      }
    }

    useEffect(() => {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    });
  };

  const nextPicture = (step, foods) => {
    const len = foods.length;
    let index = selectedIndex + step;
    if (index >= len) {
      index = 0;
    } else if (index < 0) {
      index = len - 1;
    }
    setStatus(false);
    setCommentModal(false);
    setComposedComment('');
    setSelectedIndex(index);
  };

  return (
    <Modal onClose={onClose}>
      <Container>
        {foods.length > 1 ? (
          <>
            <TrackableElement
              name='Previous Picture'
              type={ClickElementTypes.BUTTON}
              source={MODAL_NAME}
              eventHandlerName={EventHandlerNames.ONCLICK}>
              <LeftArrow onClick={() => nextPicture(-1, foods)}>
                <ImageToggleOnMouseOver
                  primaryImg={arrowLeft}
                  secondaryImg={arrowLeftHover}
                  tooltipID='leftArrow'
                  tooltipText='Previous'
                  key={`previous-picture-${foods[selectedIndex].id}`}
                />
              </LeftArrow>
            </TrackableElement>
            <Image src={foods[selectedIndex].image_url} alt='img' />
            <LabelSpan>{`${foods[selectedIndex].label} - ${foods[selectedIndex].datetimeFormatted}`}</LabelSpan>
            <TrackableElement
              name='Next Picture'
              type={ClickElementTypes.BUTTON}
              source={MODAL_NAME}
              eventHandlerName={EventHandlerNames.ONCLICK}>
              <RightArrow onClick={() => nextPicture(+1, foods)}>
                <ImageToggleOnMouseOver
                  primaryImg={arrowRight}
                  secondaryImg={arrowRightHover}
                  tooltipID='rightArrow'
                  tooltipText='Next'
                  key={`next-picture-${foods[selectedIndex].id}`}
                />
              </RightArrow>
            </TrackableElement>
          </>
        ) : (
          <>
            <Image
              src={foods[selectedIndex].image_url}
              alt='img'
              style={{ marginRight: '-15px' }}
            />
            <LabelSpan>{`${foods[selectedIndex].label} - ${foods[selectedIndex].datetimeFormatted}`}</LabelSpan>
          </>
        )}
        <Comment>
          <Header>
            <SectionImage>
              {patient.avatar?.url ? (
                <>
                  <Avatar
                    src={patient.avatar.url}
                    alt={`${patient.first_name} ${patient.last_name}`}
                  />
                </>
              ) : (
                <>
                  {' '}
                  <Icon icon='userBubble' size={35} color='turquoise' />{' '}
                </>
              )}
            </SectionImage>
            <SectionDetails>
              <div>{`${patient.first_name} ${patient.last_name}`}</div>
              <div>{`Posted ${moment.unix(foods[selectedIndex].timestamp).fromNow()}`}</div>
            </SectionDetails>
          </Header>
          <Line />
          <Body>
            {loading ? (
              <Loading />
            ) : (
              comments.map((singleComment) => (
                <div key={`wrapper-${singleComment.id}`}>
                  <Comments key={`comment-${singleComment.id}`}>
                    <div>
                      <Avatar src={singleComment.sender_avatar_url} />
                    </div>
                    <SectionDetails>
                      <div>{singleComment.sender_name}</div>
                      {singleComment.content === IMAGE_IDENTIFIER ? (
                        <ClickableImage
                          imageURL={singleComment.image_url}
                          mixpanelProps={{ name: 'Picture in comments', source: MODAL_NAME }}
                        />
                      ) : (
                        <div>{singleComment.content}</div>
                      )}
                    </SectionDetails>
                  </Comments>
                </div>
              ))
            )}
          </Body>
          <Footer>
            <PostCommentHeader>
              <div
                data-tip='Find Pre-defined messages'
                data-for={'pre-defined-messages'}
                key={'pre-defined-messages'}>
                <ReactTooltip id={'pre-defined-messages'} place='top' type='dark' effect='solid' />
                <TrackableElement
                  name='Find Predefined Messages'
                  type={ClickElementTypes.BUTTON}
                  source={MODAL_NAME}
                  eventHandlerName={EventHandlerNames.ONCLICK}>
                  <Button onClick={() => handleIconClick()}>
                    <Icon icon='addMessage' size={23} color={status ? 'teal' : 'gray'} />
                  </Button>
                </TrackableElement>
              </div>

              <PostComment>
                <p value={composedComment}>Post a Comment</p>
              </PostComment>
            </PostCommentHeader>
            <form onSubmit={handleSubmitComment}>
              <Input
                autoFocus
                onChange={(e) => {
                  const sendModifierKeys = !e.shiftKey || e.ctrlKey || e.metaKey;
                  if (e.keyCode === 13 && sendModifierKeys) {
                    e.preventDefault();
                    handleSubmitComment(e);
                  } else {
                    setComposedComment(e.target.value);
                  }
                }}
                onKeyDown={(e) => {
                  const sendModifierKeys = !e.shiftKey || e.ctrlKey || e.metaKey;
                  const sendAndCloseModifierKeys = e.ctrlKey || e.metaKey;
                  if (e.keyCode === 13 && sendModifierKeys) {
                    e.preventDefault();
                    handleSubmitComment(e);
                  } else if (sendAndCloseModifierKeys) {
                    setAutoNext(true);
                  } else {
                    setAutoNext(false);
                  }
                }}
                onKeyUp={(e) => {
                  if (e.ctrlKey || e.metaKey) {
                    setAutoNext(true);
                  } else {
                    setAutoNext(false);
                  }
                }}
                value={composedComment}
              />
              {commenting()}
              <SubmitButton
                type='submit'
                disabled={!actionEnabled}>{`${currentActionLabel} ↩`}</SubmitButton>
              <br />
              <SpanLabel>
                <b>Shift + ↩</b>: New line.
              </SpanLabel>
              <br />
              <SpanLabel>
                <b>Cmd/Ctrl + ↩</b>: {nextActionLabel}.
              </SpanLabel>
            </form>
            {useOutsideAlerter(wrapperRef)}
          </Footer>
        </Comment>
      </Container>
    </Modal>
  );
};

ViewFood.propTypes = {
  onClose: PropTypes.func.isRequired,
  patientId: PropTypes.number.isRequired,
  element_id: PropTypes.number,
  elements: PropTypes.array,
};

ViewFood.defaultProps = {
  element_id: '',
  elements: [],
};

export default ViewFood;
