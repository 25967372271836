import { Add, Close, Delete } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { MEDICAL_PROBLEMS_WITH_ICD10_CODES } from 'node-api/scribe/icd10-codes';
import { MedicalProblem } from 'node-api/scribe/medical';
import {
  Laboratory,
  MedicalProblemsContentType,
  MeetingSummaryStage,
} from 'node-api/scribe/scribe';
import { MouseEvent, useState } from 'react';
import { getFormattedDateFromISOString } from 'utils/dateHelpers';
import { transformStringWithSpacesToKebab } from 'utils/stringHelpers';
import '../../css/note-edition.css';
import { CustomTable } from '../CustomTable';
import { SectionProps } from './render-sections';

export type ProblemOption = { label: MedicalProblem; value: string };

const popoverStatesByMedicalProblems = Object.values(MedicalProblem).reduce(
  (pv, cv) => ({ ...pv, [transformStringWithSpacesToKebab(cv)]: false }),
  {} as { [pvqProblem: string]: boolean }
);

export const MedicalProblemSection = ({
  content,
  stage,
  handlers,
}: SectionProps<MedicalProblemsContentType>) => {
  const [openPopover, setOpenPopover] = useState<{ [pvqProblem: string]: boolean }>(
    popoverStatesByMedicalProblems
  );
  const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null);
  const [selectedProblem, setSelectedProblem] = useState<ProblemOption | null>(null);
  const [showAutocompleteError, setShowAutocompleteError] = useState<boolean>(false);

  const handleOpenPopover = (e: MouseEvent, popoverName: string) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setOpenPopover((prev) => ({ ...prev, [popoverName]: !openPopover[popoverName] }));
  };

  const handleClosePopover = (e: MouseEvent, popoverName: string) => {
    e.stopPropagation();
    setAnchorEl(null);
    setOpenPopover((prev) => ({ ...prev, [popoverName]: !openPopover[popoverName] }));
  };

  const medicalProblemOptions = Object.entries(MEDICAL_PROBLEMS_WITH_ICD10_CODES).map(
    ([displayName, { mainCode }]) => ({
      label: displayName,
      value: mainCode,
    })
  );

  const laboratoryColumns: GridColDef[] = [
    {
      field: 'observation',
      headerName: 'Observation',
      flex: 1,
      minWidth: 150,
      editable: true,
      sortable: false,
    },
    {
      field: 'value',
      headerName: 'Value',
      flex: 1,
      minWidth: 150,
      editable: true,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'resultDate',
      headerName: 'Result Date',
      flex: 1,
      minWidth: 150,
      renderCell: (row) => getFormattedDateFromISOString(row.value, 'YYYY-MM-DD'),
      align: 'right',
      headerAlign: 'right',
      sortable: false,
    },
  ];

  // TODO: Uncomment this code when we have the medical configuration ready to associate them with the medical problems section

  /*
const examsColumns: GridColDef[] = [
    {
      field: 'procedure',
      headerName: 'Procedure',
      flex: 1,
      minWidth: 250,
      type: 'string',
      sortable: false,
    },
    {
      field: 'code',
      headerName: 'Code',
      flex: 1,
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 50,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
      minWidth: 50,
      renderCell: (row) => getFormattedDateFromISOString(row.value, 'YYYY-MM-DD'),
      align: 'right',
      headerAlign: 'right',
      sortable: false,
    },
  ];
  */

  return (
    <Box mb={2} display='flex' flexDirection='column'>
      <Typography fontWeight='600' mb={1}>
        Problems
      </Typography>
      <Box>
        {content.data.map((medicalProblem, index) => {
          const {
            plan,
            history,
            dataReview,
            subjectiveUpdates,
            name,
            icd10Code,
            assessmentAndDiscussion,
          } = medicalProblem ?? {};

          if (!content.sectionId || !medicalProblem) {
            return [];
          }

          return (
            <Box>
              <Accordion defaultExpanded className='note-edition-accordion'>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography fontWeight='600'>{`${name} - ${icd10Code}`}</Typography>
                  <Popover
                    open={openPopover?.[transformStringWithSpacesToKebab(name)]}
                    key={`${name}-${index}`}
                    anchorEl={anchorEl as Element}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}>
                    <Typography sx={{ p: 2 }}> Are you sure you want to delete it?</Typography>

                    <Box display='flex' alignItems='center' justifyContent='center'>
                      <Button
                        className='medical-problem-section-button cancel-problem-button'
                        startIcon={<Close color='error' />}
                        variant='outlined'
                        onClick={(e) =>
                          handleOpenPopover(e, transformStringWithSpacesToKebab(name))
                        }>
                        Cancel
                      </Button>

                      <Button
                        className='medical-problem-section-button remove-problem-button'
                        key={`remove-button-${index}`}
                        startIcon={<Delete />}
                        variant='contained'
                        onClick={(e) => {
                          handleClosePopover(e, transformStringWithSpacesToKebab(name));

                          handlers.handleDeleteProblem(index);
                        }}>
                        Remove
                      </Button>
                    </Box>
                  </Popover>

                  <Button
                    className='medical-problem-section-button delete-problem-button'
                    key={`delete-problem-button-${index}`}
                    startIcon={<Close color='error' />}
                    onClick={(e) => handleOpenPopover(e, transformStringWithSpacesToKebab(name))}>
                    Delete this problem
                  </Button>
                </AccordionSummary>

                <Box mb={2}>
                  <Typography mb={1}>History:</Typography>
                  <TextField
                    multiline
                    value={history}
                    fullWidth
                    onChange={(v) =>
                      handlers.handleChangeMedicalProblem({
                        ...content,
                        data: {
                          ...medicalProblem,
                          history: v.target.value,
                        },
                        index,
                      })
                    }
                  />
                </Box>

                <Box mb={2} className='note-edition-data-review-table'>
                  <Typography mb={2}>Data Review:</Typography>
                  <Box>
                    <Typography mb={2} mt={2} fontWeight={600}>
                      Laboratories
                    </Typography>

                    <CustomTable
                      columns={laboratoryColumns}
                      data={dataReview?.labs || []}
                      onChange={(rows) => {
                        handlers.handleChangeMedicalProblem({
                          ...content,
                          data: {
                            ...medicalProblem,
                            dataReview: {
                              ...medicalProblem.dataReview,
                              labs: rows as Laboratory[],
                            },
                          },
                          index,
                        });
                      }}
                    />
                  </Box>

                  {/*
                  // TODO: Uncomment this code when we have the medical configuration ready to associate them with the medical problems section
                  <Box>
                    <Typography mb={2} mt={2} fontWeight={600}>
                      Exams
                    </Typography>

                    <CustomTable columns={examsColumns} data={dataReview?.exams || []} />
                  </Box> */}
                </Box>

                <Box mb={2}>
                  <Typography mb={1}>Subjective Updates:</Typography>
                  <TextField
                    disabled={stage === MeetingSummaryStage.PreVisit}
                    multiline
                    value={subjectiveUpdates}
                    fullWidth
                    onChange={(v) =>
                      handlers.handleChangeMedicalProblem({
                        ...content,
                        data: {
                          ...medicalProblem,
                          subjectiveUpdates: v.target.value,
                        },
                        index,
                      })
                    }
                  />
                </Box>

                <Box mb={2}>
                  <Typography mb={1}>Assessment and Discussion:</Typography>
                  <TextField
                    disabled={stage === MeetingSummaryStage.PreVisit}
                    multiline
                    value={assessmentAndDiscussion}
                    fullWidth
                    onChange={(v) =>
                      handlers.handleChangeMedicalProblem({
                        ...content,
                        data: {
                          ...medicalProblem,
                          assessmentAndDiscussion: v.target.value,
                        },
                        index,
                      })
                    }
                  />
                </Box>

                <Box mb={2}>
                  <Typography mb={1}>Plan:</Typography>
                  <TextField
                    disabled={stage === MeetingSummaryStage.PreVisit}
                    multiline
                    value={plan}
                    fullWidth
                    onChange={(v) =>
                      handlers.handleChangeMedicalProblem({
                        ...content,
                        data: {
                          ...medicalProblem,
                          plan: v.target.value,
                        },
                        index,
                      })
                    }
                  />
                </Box>
              </Accordion>
            </Box>
          );
        })}
      </Box>

      <Box
        display='flex'
        mt={2}
        mb={2}
        pt={5}
        sx={{
          borderTop: '1px solid #DBDBDB',
        }}>
        <Autocomplete
          value={selectedProblem}
          fullWidth
          getOptionDisabled={(option) => !!content.data.find((p) => p.name === option.label)}
          disablePortal
          onChange={(event: unknown, newValue: { label: string; value: string } | null) => {
            setShowAutocompleteError(false);
            setSelectedProblem(newValue as ProblemOption);
          }}
          options={medicalProblemOptions}
          sx={{ width: 'calc(70% - 1em)', marginRight: '1em' }}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Search problem'
              sx={{
                height: '100%',
              }}
              error={showAutocompleteError}
              helperText={showAutocompleteError ? 'Please select a problem to add it' : ''}
            />
          )}
        />
        <Button
          startIcon={<Add />}
          variant='outlined'
          className='medical-problem-section-button add-other-problem'
          onClick={() => {
            if (selectedProblem) {
              setShowAutocompleteError(false);
              setSelectedProblem(null);
              handlers.handleAddProblem(selectedProblem);
            } else {
              setShowAutocompleteError(true);
            }
          }}
          size='medium'>
          Add other problem
        </Button>
      </Box>
    </Box>
  );
};
