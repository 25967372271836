import api from 'api';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import useForm from 'react-hook-form';
import * as yup from 'yup';

import { DATE_FORMAT } from 'services/mixpanel/mixpanel-service';
import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { ClickElementTypes } from 'services/mixpanel/mixpanel.types';
import { ChartContext } from 'state/contexts/chart';
import { EventContext } from 'state/contexts/event';
import { PatientContext } from 'state/contexts/patient';
import { debug, escapeHTMLData } from 'utils/helpers';

import { Button, CheckBox, Datepicker, Input, Modal, TextArea } from 'components/elements';
import { ActionTypes } from 'state/reducers/chart';
import { Actions, Display, Header, PinEvent } from './style';

const schema = yup.object().shape({
  event_name: yup.string().required('An event name is required'),
  event_start_date: yup
    .date()
    .typeError('Event start date must be a valid date')
    .required('A start date is required'),
  event_end_date: yup
    .date()
    .min(yup.ref('event_start_date'), 'Start date must be earlier than End date')
    // .optional()
    .typeError('Event end date must be a valid date')
    .required('An end date is required'),
  event_note: yup.string(),
});

const AddNewEventNote = ({ onClose }) => {
  const { patientState } = useContext(PatientContext);
  const { eventDispatch } = useContext(EventContext);
  const { chartDispatch } = useContext(ChartContext);

  const patient = patientState.current;
  const [eventPinned, setEventPinned] = useState(true);

  const { handleSubmit, register, errors, formState, setValue, getValues, watch } = useForm({
    validationSchema: schema,
  });
  watch(['event_start_date']);
  register({ name: 'event_start_date' });
  watch(['event_end_date']);
  register({ name: 'event_end_date' });

  const onSubmit = async (payload) => {
    const { event_name, event_start_date, event_end_date, event_note } = payload;

    try {
      const { data } = await api().post(`/patients/${patient.uuid}/events?auth_token=AUTH_TOKEN`, {
        auth_token: 'AUTH_TOKEN',
        name: event_name,
        start_date: event_start_date,
        end_date: event_end_date,
        note: event_note,
        pinned: eventPinned,
        description: escapeHTMLData(`${event_name} (${event_start_date} - ${event_end_date})`),
      });

      if (data.success) {
        if (eventPinned) {
          eventDispatch({
            type: 'ADD',
            payload: {
              patientId: patient.id,
              data: data.event,
            },
          });
        }

        MixpanelWrapperInstance.trackClick({
          name: 'Add Event',
          type: ClickElementTypes.BUTTON,
          Source: 'Add New Event Modal',
          inputData: {
            name: event_name,
            startDate: moment(event_start_date).format(DATE_FORMAT),
            endDate: moment(event_end_date).format(DATE_FORMAT),
            note: event_note,
            pinned: eventPinned,
          },
        });

        chartDispatch({
          type: ActionTypes.ADD,
          payload: {
            type: 'events',
            values: {
              start_date: event_start_date,
              end_date: event_end_date,
              value: event_name,
            },
          },
        });
      } else {
        debug(data.errors);
      }

      return onClose();
    } catch (err) {
      debug(err);
    }
  };

  return (
    <Modal onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Header>
          <h2>Add New Event</h2>
          <p>Add a new Event into the graph</p>
        </Header>

        <Input
          errors={errors.event_name}
          label='Event Name'
          name='event_name'
          register={register}
          type='text'
          style={{ width: '100%' }}
        />

        <Display>
          <Datepicker
            errors={errors.event_start_date}
            label='Event Start Date'
            name='event_start_date'
            date={getValues().event_start_date}
            setValue={setValue}
            dateFormat='yyyy-MM-dd'
          />
          <Datepicker
            errors={errors.event_end_date}
            label='Event End Date'
            name='event_end_date'
            date={getValues().event_end_date}
            setValue={setValue}
            dateFormat='yyyy-MM-dd'
          />
        </Display>

        <TextArea
          errors={errors.event_note}
          label='Event Note'
          name='event_note'
          register={register}
          type='text'
          width='100%'
          rows={2}
        />

        <PinEvent>
          <CheckBox checked={eventPinned} onClick={() => setEventPinned((state) => !state)} />
          Pin this Event into Profile
        </PinEvent>

        <Actions>
          <Button variant='link' onClick={onClose}>
            Cancel action
          </Button>
          <Button type='submit' submitting={formState.isSubmitting}>
            Add Event
          </Button>
        </Actions>
      </form>
    </Modal>
  );
};

AddNewEventNote.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AddNewEventNote;
