/* eslint-disable */
import api from 'api';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import useForm from 'react-hook-form';

import { ConfirmationContext } from 'state/contexts/confirmation';
import { PatientContext } from 'state/contexts/patient';

import ListAltIcon from '@material-ui/icons/ListAlt';
import { AutoComplete, Button, Modal, Task } from 'components/elements';
import Icon from 'components/icon';
import Toast from 'components/toast';
import { UserContext } from 'state/contexts/user';
import { ActionTypes } from 'state/reducers/confirmation';
import { debug } from 'utils/helpers';
import { Item, Record } from '../../add-tag/style';
import CreateTaskList from './create-new-task-list';
import { Actions, AddNewTaskRow, Display, Header, TaskRows } from './style';

const AddNewTasksList = ({ open, setOpen, onClose1 }) => {
  const { confirmationDispatch } = useContext(ConfirmationContext);
  const { patientState } = useContext(PatientContext);
  const { userState } = useContext(UserContext);

  const patient = patientState.current;
  const [openCreate, setOpenCreate] = useState(true);
  const [close, setClose] = useState(false);
  const [boolChange, setBoolChange] = useState(false);

  const [state, setState] = useState();
  const [selected, setSelected] = useState('');
  const [reload, setReload] = useState(Date.now());
  const [submitting, setSubmitting] = useState(false);

  const newTaskRow = {
    description: '',
    role_id: 1,
    start_date_in_days: 'today',
    due_date_in_days: '0',
    action: '',
  };
  const [taskRows, setTaskRows] = useState([newTaskRow]);
  const [roles, setRoles] = useState([]);
  const [taskListLibs, setTaskListLibs] = useState([]);
  const [selectedtaskListLibName, setSelectedtaskListLibName] = useState('');
  const [selectedtaskListLibId, setSelectedtaskListLibId] = useState();

  const { handleSubmit, errors, formState } = useForm({});

  const submitTasksList = async () => {
    const origu = localStorage.getItem('Origu') && localStorage.getItem('Origu').split('|');
    const original_user_uuid = (origu && origu[0]) || null;
    const original_user = (origu && origu[1]) || null;

    formatTaskRows(taskRows);

    const {
      data: { data },
    } = await api().post('tasks/submit_task_list', {
      auth_token: 'AUTH_TOKEN',
      patient_uuid: patient.uuid,
      tasks: taskRows,
      ...(userState.isOriginalUser === false &&
        original_user !== null && { original_user_uuid, original_user }),
    });
  };

  const onYes = async (onClose) => {
    try {
      const origu = localStorage.getItem('Origu') && localStorage.getItem('Origu').split('|');
      const original_user_uuid = (origu && origu[0]) || null;
      const original_user = (origu && origu[1]) || null;

      formatTaskRows(taskRows);

      const {
        data: { data },
      } = await api().post('tasks/submit_task_list', {
        auth_token: 'AUTH_TOKEN',
        patient_uuid: patient.uuid,
        tasks: taskRows,
        ...(userState.isOriginalUser === false &&
          original_user !== null && { original_user_uuid, original_user }),
      });

      onClose();
      handleClose();
    } catch (err) {
      debug(err);
      onClose();
      handleClose();
      // close the 1st modal
      onClose1();

      if (err.response.data.role_names) {
        let roles_formated = [];
        const lenght = err.response.data.role_names.length;

        if (lenght === 1) roles_formated = err.response.data.role_names;
        else if (lenght === 2)
          roles_formated.push(
            `${err.response.data.role_names[0]} and ${err.response.data.role_names[1]}`
          );
        else {
          for (let i = 0; i < lenght; i++) {
            if (i === 0) roles_formated.push(err.response.data.role_names[i]);
            else if (i < lenght - 2) roles_formated.push(` ${err.response.data.role_names[i]}`);
          }
          roles_formated.push(
            ` ${err.response.data.role_names[lenght - 2]} and ${
              err.response.data.role_names[lenght - 1]
            }`
          );
        }

        Toast.show(
          'error',
          `We could not deploy this task list because the patient does't have a "${roles_formated}" assigned. Please assign ${
            lenght > 1 ? 'them' : 'it'
          } first.`,
          {
            size: 'big',
            autoClose: 5000,
          }
        );
      }
    }
  };

  const onNo = async (onClose) => {
    onClose();
  };

  const handleClose = () => {
    setOpen(!open);
    setClose(open);
  };

  const confirmationSubmit = () => {
    confirmationDispatch({
      type: ActionTypes.SHOW,
      payload: {
        onYes,
        onNo,
        message: `Are you sure you want to submit the list?`,
      },
    });
  };

  const handleNewTask = () => {
    setTaskRows(taskRows.concat(newTaskRow));
  };

  const formatTaskRows = (taskRows) => {
    // Send Data Formated
    for (const task of taskRows) {
      setState({ value: (task.due_date_in_days = parseInt(task.due_date_in_days, 10)) });
      setState({ value: (task.role_id = parseInt(task.role_id, 10)) });
      if (task.start_date_in_days === 'today') {
        setState({ value: (task.start_date_in_days = parseInt(0, 10)) });
      } else {
        setState({
          value: (task.start_date_in_days = parseInt(task.start_date_in_days, 10)),
        });
      }
    }
    setTaskRows(taskRows);
  };

  const handleOnChange = (task_id, field_name, value) => {
    if (selectedtaskListLibName !== '') {
      setBoolChange(true);
    } else {
      setBoolChange(false);
    }
    setState({ value: (taskRows[task_id][field_name] = value) });
    setTaskRows(taskRows);
  };

  const handleSaveTask = (task_id) => {
    setState({ value: (taskRows[task_id].action = 'new') });
    formatTaskRows(taskRows);
    // setTaskRows(taskRows);
    setBoolChange(true);
  };

  // remove locally
  const handleRemoveTask = (task_id) => {
    setTaskRows(taskRows.filter((item) => item !== taskRows[task_id]));
    setReload(Date.now());
    if (selectedtaskListLibName !== '') {
      setBoolChange(true);
    }
    if (taskRows.length === 1) setTaskRows([newTaskRow]);
  };

  // update task list in the DB: save
  const handleUpdatedTaskList = async () => {
    formatTaskRows(taskRows);
    setSubmitting(true);
    try {
      // delete the old list
      await api().delete(`task_lists/${selectedtaskListLibId}?auth_token=AUTH_TOKEN`, {});
      // create a new one
      await api().post('task_lists', {
        auth_token: 'AUTH_TOKEN',
        name: selectedtaskListLibName,
        task_templates_attributes: taskRows,
      });

      const { data } = await api().get('task_lists?auth_token=AUTH_TOKEN');
      setTaskListLibs(data);
      // need to set the new id of the task updated
      setSelectedtaskListLibId(data.filter((item) => item.name === selectedtaskListLibName)[0].id);
      // disable save button
      setBoolChange(false);
    } catch (err) {
      debug(err);
    }
    setSubmitting(false);
  };

  // create task list in the DB: save as
  const handleCreateTaskList = () => {
    setSelected('create');
    setOpenCreate(true);
  };

  const isTaskRowFilled = (task_row) => {
    return (
      task_row.description !== '' &&
      task_row.role_id !== '' &&
      task_row.start_date_in_days !== '' &&
      task_row.due_date_in_days !== ''
    );
  };

  const isTaskRowsFilled = (taskRows) => {
    return taskRows.length > 0 && taskRows.every((task_row) => task_row.action !== '');
  };

  const showSaveButton = (taskRows) => {
    if (isTaskRowsFilled(taskRows) && selectedtaskListLibName !== '' && boolChange) {
      return false;
    }
    return true;
  };

  const getRoles = async () => {
    try {
      const { data } = await api().get('roles/AUTH_TOKEN');
      setRoles(data);
    } catch (err) {
      debug(err);
    }
  };

  const getTaskListLibrary = async () => {
    try {
      const { data } = await api().get('task_lists?auth_token=AUTH_TOKEN');
      setTaskListLibs(data);
    } catch (err) {
      debug(err);
    }
  };

  useEffect(() => {
    getRoles();
    getTaskListLibrary();
  }, []);

  const getAddNewTaskLink = (index, taskRows) => {
    return (
      <AddNewTaskRow key={`add-new-task-row-${index}`}>
        Add New Task
        <Button variant='link' onClick={handleNewTask} disabled={!isTaskRowsFilled(taskRows)}>
          <Icon icon='plus' background='teal' color='white' size={10} />
        </Button>
      </AddNewTaskRow>
    );
  };

  return (
    <>
      {selected === 'create' && openCreate && (
        <CreateTaskList
          openCreate={openCreate}
          setOpenCreate={setOpenCreate}
          taskRows={taskRows}
          setTaskRows={setTaskRows}
          formatTaskRows={formatTaskRows}
          setTaskListLibs={setTaskListLibs}
          taskListLibs={taskListLibs}
          selectedtaskListLibName={selectedtaskListLibName}
        />
      )}

      <Modal onClose={handleClose}>
        <form onSubmit={handleSubmit(confirmationSubmit)}>
          <Actions>
            <Header style={{ width: '50%' }}>
              <h2>Create New Task List</h2>
              <p>Assign a tasks to providers</p>
            </Header>
            <div style={{ position: 'relative', display: 'inline-block' }}>
              <ListAltIcon style={{ position: 'absolute', top: 13, width: 30, height: 30 }} />
              <AutoComplete
                id='task-list-library-input'
                name='task_list_library'
                marginbottom={1}
                width={250}
                placeholder='Task List Library'
                getData={() => taskListLibs}
                onChange={(e, opt) => {
                  if (opt) {
                    setSelectedtaskListLibName(opt.name);
                    setSelectedtaskListLibId(opt.id);
                    setTaskRows(opt.task_templates);
                    setBoolChange(false);
                  } else {
                    setTaskRows([newTaskRow]);
                    setSelectedtaskListLibName('');
                  }
                  setReload(Date.now());
                }}
                renderOption={(opt) => (
                  <Record key={`option-${opt.id}`}>
                    <Item>{opt.name}</Item>
                  </Record>
                )}
              />
            </div>
            <Button
              type='button'
              onClick={handleUpdatedTaskList}
              submitting={submitting}
              disabled={showSaveButton(taskRows)}>
              {!submitting && <Icon icon='save' color='white' size={30} />}
              Save Task List
            </Button>
            <Button
              type='button'
              onClick={handleCreateTaskList}
              disabled={!isTaskRowsFilled(taskRows)}>
              <Icon icon='save' color='white' size={30} />
              Save As New
            </Button>
            <Button
              type='submit'
              submitting={formState.isSubmitting}
              disabled={!isTaskRowsFilled(taskRows)}>
              Submit
            </Button>
          </Actions>
          {taskRows.length === 0 && (
            <AddNewTaskRow key='add-new-task-row'>
              Add New Task
              <Button variant='link' onClick={handleNewTask}>
                <Icon icon='plus' background='teal' color='white' size={10} />
              </Button>
            </AddNewTaskRow>
          )}
          {taskRows.map((task_row, index) => (
            <TaskRows key={`task-rows-${index}`}>
              {taskRows.length === index + 1 &&
                task_row.action !== 'saved' &&
                task_row.action !== 'new' &&
                getAddNewTaskLink(index, taskRows)}
              <Display key={`disaplay-row-${index}${reload}`}>
                <Task
                  key={`task-row-${index}`}
                  description={task_row.description}
                  role_id={task_row.role_id || task_row.id}
                  start_date_in_days={task_row.start_date_in_days}
                  due_date_in_days={task_row.due_date_in_days}
                  handleOnChange={handleOnChange}
                  roles={roles}
                  task_id={index}
                />
                {task_row.action !== 'saved' && task_row.action !== 'new' ? (
                  <Button
                    variant='link'
                    onClick={() => handleSaveTask(index)}
                    disabled={!isTaskRowFilled(task_row)}>
                    <Icon icon='plus' background='teal' color='white' size={10} />
                  </Button>
                ) : (
                  <Button variant='link' onClick={() => handleRemoveTask(index)}>
                    <Icon icon='close' background='red' color='white' size={10} />
                  </Button>
                )}
              </Display>
              {taskRows.length === index + 1 &&
                (task_row.action === 'saved' || task_row.action === 'new') &&
                getAddNewTaskLink(index, taskRows)}
            </TaskRows>
          ))}
        </form>
      </Modal>
    </>
  );
};

AddNewTasksList.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default AddNewTasksList;
