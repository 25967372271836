export enum EventHandlerNames {
  ONCLICK = 'onClick',
  ONCHANGE = 'onChange',
}

export enum ClickElementTypes {
  LINK = 'Link',
  BUTTON = 'Button',
  TAB = 'Tab',
  IMAGE = 'Image',
  ACCORDION = 'Accordion',
}

export enum ChangeElementTypes {
  DROPDOWN = 'Dropdown',
  CHECKBOX = 'Checkbox',
  MATERIAL_DATE_PICKER = 'Material date picker',
}

export interface TrackProps {
  customEventName?: string;
  name: string;
  source?: string;
  [key: string]: unknown;
}

export interface TrackClickProps extends TrackProps {
  type: ClickElementTypes;
  inputData?: { [key: string]: unknown };
}

export interface TrackChangeProps extends TrackProps {
  type: ChangeElementTypes;
  value: string | number | boolean;
}

export type UserProps = {
  userId?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  birthdate?: string;
  avatar?: string;
  phone?: string;
  roles?: string[];
};

export type UserForMixpanelConfig = {
  id: number;
  uuid: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  avatar: string;
  birthdate: string;
};
