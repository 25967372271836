import { Button, Datepicker, Float, Modal } from 'components/elements';
import { Constants } from 'components/main/calendar/utils';
import Toast from 'components/toast';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import useForm from 'react-hook-form';
import { PatientContext } from 'state/contexts/patient';
import { WeightsContext } from 'state/contexts/weights';
import { ActionTypes as WeightsActionTypes } from 'state/reducers/weights';
import { debug } from 'utils/helpers';
import * as yup from 'yup';
import MembersClient from '../../../../../../../node-api/members/MembersClient';
import { Actions, Display, Header } from './style';

const schema = yup.object().shape({
  weight_value: yup
    .number()
    .typeError('weight value must be a valid number')
    .required('A weight value is required'),
  weight_date: yup
    .date()
    .typeError('weight date must be a valid date')
    .required('A weight date is required'),
});

const AddWeight = ({ onClose }) => {
  const { patientState } = useContext(PatientContext);
  const { weightsDispatch } = useContext(WeightsContext);
  const { handleSubmit, register, errors, formState, setValue, getValues, watch } = useForm({
    validationSchema: schema,
  });

  watch(['weight_date']);
  register({ name: 'weight_date' });

  watch(['weight_value']);
  register({ name: 'weight_value' });

  const patient = patientState.current;
  const memberId = patient.id;

  const submit = async (payload) => {
    const { weight_value, weight_date } = payload;

    const membersServiceClient = new MembersClient();
    let newRecordId = null;
    try {
      const response = await membersServiceClient.addWeightInAllPlatforms(memberId, {
        date: moment(weight_date).format(Constants.DATE_FORMAT),
        source: 'provider-app::member-profile',
        weightInPounds: weight_value,
      });
      newRecordId = response.data.record?.processedRecords?.[0]?.id ?? null;
      // newRecordId should be always be defined else it means it was not created in health records database
    } catch (err) {
      debug(err);
      Toast.show('error', 'Unable to add a new weight');
      onClose();
      return;
    }

    // Then add the new weight
    if (newRecordId !== null) {
      weightsDispatch({
        type: WeightsActionTypes.ADD_WEIGHT,
        payload: {
          weight: {
            date: weight_date,
            id: newRecordId,
            source: '',
            value: weight_value,
          },
        },
      });
      Toast.show('success', 'Weight record created successfully');
    } else {
      Toast.show('success', 'Weight record created successfully. Please refresh the page');
    }

    onClose();
  };

  return (
    <Modal onClose={onClose}>
      <form onSubmit={handleSubmit(submit)}>
        <Header>
          <h2>Add New Weight</h2>
          <p>
            Add a new weight for patient : {patient.first_name} {patient.last_name}.
          </p>
        </Header>
        <Float
          errors={errors.weight_value}
          label='Weight value'
          name='weight_value'
          value={getValues().weight_value}
          setValue={setValue}
          endAdornment='lbs'
          width={245}
        />
        <Display>
          <Datepicker
            errors={errors.weight_date}
            label='Weight Date'
            name='weight_date'
            date={getValues().weight_date}
            setValue={setValue}
            dateFormat='yyyy-MM-dd'
            style={{ width: '100%' }}
          />
        </Display>
        <Actions>
          <Button variant='link' onClick={onClose}>
            Cancel
          </Button>
          <Button type='submit' submitting={formState.isSubmitting}>
            Submit
          </Button>
        </Actions>
      </form>
    </Modal>
  );
};

AddWeight.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AddWeight;
