import { CancelToken } from 'axios';
import { MEMBERS_WEB_APP_URL, SCHEDULE_API_BASE_URL } from 'config';
import { canMemberUseNewVideoCall } from 'helpers/members';
import moment from 'moment';
import { SharedEnaraNodeClient } from 'node-api/common/SharedEnaraNodeClient';
import AuthService, { AuthProps } from 'services/auth-service';
import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { EventNames } from 'services/mixpanel/provider-app-mixpanel-events';
import { BackgroundEvent } from 'services/mixpanel/shared-mixpanel-service';
import { SanitizedCoreAppointment, SanitizedCoreAppointmentResponse } from './types.v3';

type Attendee = 'member' | 'provider' | 'guest';

class SchedulingV3 extends SharedEnaraNodeClient {
  public async listAppointments(
    {
      memberId,
    }: {
      memberId: number;
    },
    cancelToken?: CancelToken
  ): Promise<SanitizedCoreAppointment[]> {
    const listResponse = await this.axiosClient.get<SanitizedCoreAppointmentResponse>(
      `/v3/members/${memberId}/appointments`,
      { cancelToken }
    );

    return listResponse?.data?.appointments || [];
  }

  private async getLegacyTwilioVideoUrl(
    memberInfo: { id: number; uuid: string },
    tokenFor: Attendee = 'provider'
  ) {
    const { Authorization } = AuthService.getAuth() as AuthProps;

    const response = await this.axiosClient.get<{
      success: 'true' | 'false';
      data: {
        patient_token: string;
        provider_token: string;
        user_type: 'member' | 'provider';
        authorized: true;
        patient_verified: true;
        error_message?: string;
      };
    }>(`video-call/v1/members/${memberInfo.uuid}/provider-token/${Authorization.token}`);

    if (response.status !== 200) {
      throw new Error('Unable to get provider video url');
    }

    const { data } = response;
    if (
      (data.data.user_type === 'provider',
      data.data.authorized === true && data.data.patient_verified === true)
    ) {
      let videoToken: string;
      if (tokenFor === 'provider') {
        videoToken = data.data.provider_token;
      } else {
        videoToken = data.data.patient_token;
      }
      return `${MEMBERS_WEB_APP_URL}/video-call/${videoToken}?memberId=${memberInfo.id}`;
    }

    throw new Error('Unable to get provider video url');
  }

  public async getProviderVideoUrl(
    memberInfo: { id: number; uuid: string },
    providerInfo: { id: number; uuid: string },
    tokenFor: Attendee, // To validate later in member-web-app
    calledFrom: string
  ): Promise<string> {
    // TODO: Remove all this logic about feature flag and the call for getLegacyTwilioVideoUrl
    // after releasing chime-backed video calls

    if (providerInfo.uuid.trim().length === 0) {
      throw new Error('Unable to get provider video url');
    }

    const isMemberAllowed = await canMemberUseNewVideoCall(memberInfo.id);

    MixpanelWrapperInstance.track(EventNames.MemberGetCallLink, {
      targetLabel: 'getProviderVideoUrl',
      targetType: BackgroundEvent.CallingService,
      memberUuid: memberInfo.uuid,
      providerUuid: providerInfo.uuid,
      calledFrom,
      source: 'get-provider-url',
      chimeVideoCallEnabled: isMemberAllowed,
    });

    try {
      if (!isMemberAllowed) {
        return this.getLegacyTwilioVideoUrl(memberInfo, tokenFor);
      }

      const url = `video-call/v2/meeting/patient/${memberInfo.uuid}/provider/${providerInfo.uuid}?tokenFor=${tokenFor}`;
      const { data } = await this.axiosClient.get(url);

      if (!data.success) {
        throw new Error('Unable to get provider video url');
      }

      return `${MEMBERS_WEB_APP_URL}/chime-video/${data.videoToken}?source=${calledFrom}`;
    } catch (error) {
      console.error('getProviderVideoUrl::error: ', error);

      throw new Error('Unable to get provider video url');
    }
  }

  public async sendEmailVideoLinkReminder(memberUuid: string, providerUuid: string) {
    const nowForceTime = moment().format('YYYY/MM/DD hh:mm');

    const url = `/v1/providers/${providerUuid}/members/${memberUuid}/appointments/next/reminders/email?force=true&appointmentTime=${nowForceTime}`;
    try {
      await this.axiosClient.post(url);

      return true;
    } catch (err) {
      console.error('emailVideoLinkReminder::error: ', err);
      return false;
    }
  }
}

export const schedulingV3Client = new SchedulingV3(SCHEDULE_API_BASE_URL);
