import { useEffect, useRef, useState } from 'react';

const TRIGGER_SEARCH_BY_FILTER_DELAY = 700;

const useDebounce = <T>(value: T) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setDebouncedValue(value);
    }, TRIGGER_SEARCH_BY_FILTER_DELAY);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [value]);

  return debouncedValue;
};

export default useDebounce;
