import { Dispatch, FC, PropsWithChildren, createContext, useReducer } from 'react';
import { Action, Provider, State, initialState, reducer } from '../reducers/provider';

export type ProviderContextValue = {
  providerState: State;
  providerDispatch: Dispatch<Action>;
  getProviderById: (providerId: number | undefined) => Provider | undefined;
  searchProviderByName: (name: string) => Provider | undefined;
};

const ProviderContext = createContext<ProviderContextValue>({
  providerState: initialState,
  providerDispatch: () => null,
  getProviderById: () => undefined,
  searchProviderByName: () => undefined,
});

const ProviderProvider: FC<PropsWithChildren> = ({ children }) => {
  const [providerState, providerDispatch] = useReducer(reducer, initialState);

  function getProviderById(providerId: number | undefined): Provider | undefined {
    if (!providerId) {
      return undefined;
    }

    return providerState.data.find((p) => p.id === providerId);
  }

  function searchProviderByName(name: string) {
    return providerState.data.find((p) => name?.includes(p.last_name));
  }

  return (
    <ProviderContext.Provider
      value={{ providerState, providerDispatch, getProviderById, searchProviderByName }}>
      {children}
    </ProviderContext.Provider>
  );
};

export { ProviderContext, ProviderProvider };
