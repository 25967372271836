import mixpanel from 'mixpanel-browser';
import { UserProps } from './mixpanel.types';

export enum BackgroundEvent {
  CallingService = 'callingService',
}

export enum ElementEventNames {
  ONCLICK = 'onClick',
  ONCHANGE = 'onChange',
}

export enum ClickableElement {
  ACCORDION = 'accordion',
  BACKGROUND = 'background',
  BUTTON = 'button',
  ENTRY = 'entry',
  HEADER = 'header',
  IMAGE = 'image',
  LINK = 'link',
  RATING = 'rating',
  SHORTCUT = 'shortcut',
  TAB = 'tab',
}

export enum SelectableElement {
  DROPDOWN = 'dropdown',
  CHECKBOX = 'checkbox',
  MATERIAL_DATE_PICKER = 'material date picker',
}

enum StandardProps {
  APP_NAME = 'app-name',
  APP_VERSION = 'app-version',
  APP_EXECUTION_CONTEXT = 'app-execution-context',
  TARGET_TYPE = 'target-type',
  TARGET_LABEL = 'target-label',
  TARGET_VALUE = 'target-value',
  TARGET_OLD_VALUE = 'target-old-value',
  TARGET_LOCATION = 'target-location',
}

interface ExtraProps {
  [key: string]:
    | string
    | number
    | boolean
    | Date
    | string[]
    | number[]
    | boolean[]
    | Date[]
    | undefined;
}

interface TrackProps<T extends string> extends ExtraProps {
  eventName: T;
  targetLabel?: string;
  targetLocation?: string;
  targetType?: ClickableElement | SelectableElement | BackgroundEvent;
  targetValue?: string | number | boolean;
  targetOldValue?: string | number | boolean;
}

/**
 * TODO: Move to a shared package
 * (Used by both provider-app and member-web-app)
 */
export class SharedMixpanelService<T extends string> {
  private static config = {};

  static configure(props: { mixpanelKey: string; appName: string; appVersion: string }) {
    mixpanel.init(props.mixpanelKey, {}, 'shared');
    this.config = {
      [StandardProps.APP_NAME]: props.appName,
      [StandardProps.APP_VERSION]: props.appVersion,
    };
  }

  identify(uuid: string) {
    mixpanel.shared.identify(uuid);
  }

  set(props: UserProps) {
    const updatedProps = {
      'User Id': props.userId,
      $first_name: props.firstName,
      $last_name: props.lastName,
      $email: props.email,
      $phone: props.phone,
      $avatar: props.avatar,
      Birthdate: props.birthdate,
      Roles: props.roles,
    };
    mixpanel.shared.people.set(updatedProps);
  }

  track({
    eventName,
    targetLabel,
    targetType,
    targetLocation,
    targetValue,
    targetOldValue,
    ...restProps
  }: TrackProps<T>) {
    const newProps = {
      ...SharedMixpanelService.config,
      [StandardProps.TARGET_LABEL]: targetLabel?.toLowerCase(),
      [StandardProps.TARGET_TYPE]: targetType?.toLowerCase(),
      [StandardProps.TARGET_LOCATION]: targetLocation?.toLowerCase(),
      [StandardProps.TARGET_VALUE]: targetValue,
      [StandardProps.TARGET_OLD_VALUE]: targetOldValue,
      ...restProps,
    };
    mixpanel.shared.track(eventName.toLowerCase(), newProps);
  }
}

export default SharedMixpanelService;
