import { MemberPreference, Preferences } from 'node-api/members/member.types';
import MembersClient from 'node-api/members/MembersClient';

export const getVideoCallPreference = async (
  memberId: number
): Promise<MemberPreference | null> => {
  const memberPreferences = await new MembersClient().getMemberPreferences(memberId);

  return memberPreferences?.find((p) => p.preference.name === Preferences.UseNewVideoCall) ?? null;
};

export async function canMemberUseNewVideoCall(memberId: number): Promise<boolean> {
  return (await getVideoCallPreference(memberId))?.value ?? false;
}

export function getPreferenceByName(listOfPreferences: MemberPreference[], preferenceName: string) {
  return listOfPreferences.find(({ preference }) => preference.name === preferenceName);
}
