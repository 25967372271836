import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { Program } from 'services/chart/chart-types';
import ChartService from 'services/chart/chartService';
import { PatientContext } from 'state/contexts/patient';
import { CurrentProgram } from 'state/reducers/patient';

const useLastProgram = () => {
  const { patientState } = useContext(PatientContext);
  const [lastProgram, setLastProgram] = useState<CurrentProgram | null>(null);

  const currentPatient = patientState.current;

  useEffect(() => {
    if (!currentPatient?.id) {
      return;
    }

    const callWeightData = async () => {
      const service = new ChartService();
      const data = await service.getWeightData(currentPatient.id.toString());

      if (!data || data.programs.length === 0) {
        return;
      }

      // ASC order by start_date, id
      const programs = data.programs.sort((objA, objB) => {
        const dateA = moment(objA.start_date);
        const dateB = moment(objB.start_date);

        if (dateA.isSame(dateB)) {
          return objA.user_program_id - objB.user_program_id;
        }

        return dateA.diff(dateB);
      });

      const currentProgram: Program = programs[programs.length - 1];

      setLastProgram({
        id: currentProgram.id,
        displayValue: currentProgram.display_value,
        startDate: currentProgram.start_date,
        endDate: currentProgram.end_date,
        value: currentProgram.value || '',
        userProgramId: currentProgram.user_program_id,
        duration: currentProgram.duration,
      });
    };

    callWeightData();
  }, [currentPatient?.id]);

  return lastProgram;
};

export default useLastProgram;
