import MixpanelService from './mixpanel-service';
import { TrackChangeProps, TrackClickProps, UserForMixpanelConfig } from './mixpanel.types';
import { ProviderAppMixpanelInstance } from './provider-app-mixpanel-service';

export class MixpanelWrapper {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  track(eventName: string, props?: any) {
    MixpanelService.track(eventName, props);

    ProviderAppMixpanelInstance.track({
      eventName,
      ...props,
    });
  }

  configureMixpanel(user: UserForMixpanelConfig) {
    MixpanelService.set({
      $first_name: user.first_name,
      $last_name: user.last_name,
      $email: user.email,
      $phone: user.phone,
      $avatar: user.avatar,
      Birthdate: user.birthdate,
      userId: user.id,
    });

    MixpanelService.identify(user.uuid);

    ProviderAppMixpanelInstance.identify(user.uuid);
    ProviderAppMixpanelInstance.set({
      userId: user.id,
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      phone: user.phone,
      avatar: user.avatar,
      birthdate: user.birthdate,
    });
  }

  trackClick({ customEventName, name, type, source, inputData, ...restProps }: TrackClickProps) {
    this.track(customEventName || `Clicked ${name}`, {
      'Target Name': name,
      'Target Type': type,
      Source: source,
      'Input Data': inputData,
      ...restProps,
    });
  }

  trackChange({ customEventName, name, type, source, value, ...restProps }: TrackChangeProps) {
    this.track(customEventName || `Changed ${name}`, {
      'Field Name': name,
      'Field Type': type,
      Source: source,
      'Field Value': value,
      ...restProps,
    });
  }
}

export const MixpanelWrapperInstance = new MixpanelWrapper();
