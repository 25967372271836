import { Button, Input, Link } from 'components/elements';
import logoSrc from 'components/navbar/assets/logo.png';
import Toast from 'components/toast';
import PropTypes from 'prop-types';
import useForm from 'react-hook-form';
import AuthService from 'services/auth-service';
import MixpanelService from 'services/mixpanel/mixpanel-service';
import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { EventNames } from 'services/mixpanel/provider-app-mixpanel-events';
import { ProviderAppMixpanelInstance } from 'services/mixpanel/provider-app-mixpanel-service';
import * as yup from 'yup';
import {
  AccountContent,
  AccountHeader,
  AccountWrapper,
  Background,
  Form,
  Logo,
  Subtitle,
} from '../account-styles';

const schema = yup.object().shape({
  phone: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email is required field'),
  password: yup.string().required('Password is a required field'),
});

const Login = ({ history }) => {
  const { handleSubmit, register, errors, formState } = useForm({
    validationSchema: schema,
  });

  const submit = async (payload) => {
    try {
      const response = await AuthService.signIn(payload);
      const { data } = response;

      AuthService.setAuth(data);
      const ROLES = data.roles?.map((role) => role.name) || [];
      MixpanelService.identify(data.user_uuid);
      MixpanelService.set({
        Roles: ROLES,
        userId: data.user_id,
      });
      // Tracking to shared Mixpanel
      ProviderAppMixpanelInstance.identify(data.user_uuid);
      ProviderAppMixpanelInstance.set({
        roles: ROLES,
      });

      MixpanelWrapperInstance.track(EventNames.SessionLogIn);

      return history.push('/');
    } catch (err) {
      Toast.show('error', 'Your username or password doesn’t exist.');
    }
  };

  return (
    <AccountWrapper>
      <Background />

      <AccountHeader>
        <Logo src={logoSrc} alt='Enara logo' />
      </AccountHeader>

      <AccountContent>
        <Subtitle>We are glad to have you back</Subtitle>
        <Form onSubmit={handleSubmit(submit)}>
          <Input errors={errors.phone} label='Email' name='phone' register={register} type='text' />

          <Input
            errors={errors.password}
            label='Password'
            name='password'
            register={register}
            type='password'
          />

          <Button type='submit' size='large' submitting={formState.isSubmitting}>
            Next
          </Button>
        </Form>

        <Link to='/forgot-password' title='Forgot password'>
          I forgot my password
        </Link>
      </AccountContent>
    </AccountWrapper>
  );
};

Login.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default Login;
