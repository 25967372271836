import api from 'api';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import useForm from 'react-hook-form';
import * as yup from 'yup';

import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { ClickElementTypes } from 'services/mixpanel/mixpanel.types';
import { PatientContext } from 'state/contexts/patient';
import { StickyContext } from 'state/contexts/sticky';
import { debug, escapeHTMLData } from 'utils/helpers';

import { Button, Modal, TextArea } from 'components/elements';
import { Actions, Header } from './style';

const schema = yup.object().shape({
  note: yup.string().required('A note is required'),
});

const AddNewStickyNote = ({ onClose }) => {
  const { patientState } = useContext(PatientContext);
  const { stickyDispatch } = useContext(StickyContext);

  const patient = patientState.current;
  const { register, handleSubmit, errors, formState } = useForm({
    validationSchema: schema,
  });

  const onSubmit = async (payload) => {
    try {
      const note = escapeHTMLData(payload.note);
      const {
        data: { data },
      } = await api().post('/sticks', {
        auth_token: 'AUTH_TOKEN',
        patient_id: patient.id,
        description: note,
      });

      MixpanelWrapperInstance.trackClick({
        name: 'Add New Sticky Note',
        type: ClickElementTypes.BUTTON,
        Source: 'Add New Sticky Note Modal',
        inputData: {
          stickyNote: note,
        },
      });

      stickyDispatch({
        type: 'ADD',
        payload: {
          patientId: patient.id,
          data,
        },
      });

      return onClose();
    } catch (err) {
      debug(err);
    }
  };

  return (
    <Modal onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Header>
          <h2>Add New Sticky Note</h2>
          <p>Create a Note for this Profile</p>
        </Header>

        <TextArea
          errors={errors.note}
          label='Sticky Note'
          name='note'
          register={register}
          type='text'
        />

        <Actions>
          <Button variant='link' onClick={onClose}>
            Cancel action
          </Button>
          <Button type='submit' submitting={formState.isSubmitting}>
            Add New Sticky Note
          </Button>
        </Actions>
      </form>
    </Modal>
  );
};

AddNewStickyNote.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AddNewStickyNote;
