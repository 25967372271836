import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  Collapse,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import List from '@mui/material/List';
import { Loading } from 'components/elements';
import Icon from 'components/icon';
import { Constants, daysDiff } from 'components/main/calendar/utils';
import Toast from 'components/toast';
import { EFeatures } from 'lib/feature/features.types';
import moment from 'moment-timezone';
import { FC, useContext, useEffect, useMemo, useState } from 'react';

import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { ChangeElementTypes, ClickElementTypes } from 'services/mixpanel/mixpanel.types';
import { EventNames } from 'services/mixpanel/provider-app-mixpanel-events';
import { ConfirmationContext } from 'state/contexts/confirmation';
import { FeaturesContext } from 'state/contexts/features';
import { PatientContext } from 'state/contexts/patient';
import { ProviderContext } from 'state/contexts/provider';
import { UserContext } from 'state/contexts/user';
import { ActionTypes } from 'state/reducers/confirmation';
import Theme from 'styles/theme';
import {
  ICard,
  ICategory,
  ICategoryInfo,
  IOpenItem,
  ISubCategory,
  ISubCategoryInfo,
} from '../../../../interfaces/cards';
import CardsService from '../../../../services/card-service';
import { InlineRecord, InlineRecordItem, Text } from '../../MyPanel/style';
import CardItem from './CardItem';
import {
  CardRecord,
  ConfirmButton,
  Content,
  Footer,
  ListCard,
  ListEmptySubCategory,
  ListSubCategoryIcon,
  MSGText,
  Section,
} from './style';

const DIETITIAN = 'Dietitian';
const OFFICIAL_CONTENT_CATEGORY = 'Official Content';
const OFFICIAL_CONTENT_CURRICULUM_CATEGORY = 'Official Content - Curriculum';
const OFFICIAL_CONTENT_SUBCATEGORY = 'official-content';
const POST_CARD_SEQUENCE_SOURCE = 'Post Card Sequence Confirmation';
const START_CURRICULUM_SOURCE = 'Start Curriculum Confirmation';

const PostCardSequence: FC<{ patientId: number }> = ({ patientId }) => {
  const { confirmationDispatch } = useContext(ConfirmationContext);
  const { patientState } = useContext(PatientContext);
  const { getProviderById } = useContext(ProviderContext);
  const { userState } = useContext(UserContext);
  const { featuresState } = useContext(FeaturesContext);

  const [loading, setLoading] = useState<boolean>(true);
  const [categories, setCategories] = useState<ICategory[] | null>();
  const [curriculumCategory, setCurriculumCategory] = useState<ICategory | null>();
  const [openCategory, setOpenCategory] = useState<IOpenItem>({ open: null });
  const [openSubCategory, setOpenSubCategory] = useState<IOpenItem>({ open: null });
  const [selectedDate, setSelectedDate] = useState<string>(moment().format());
  const [extraDays, setExtraDays] = useState<number>(0);
  let forced = false;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const patient = patientState.current!;
  const provider = getProviderById(userState.id);
  const isAllowed = useMemo(() => {
    return !!provider?.roles.some((p: { name: string }) => p.name.includes(DIETITIAN));
  }, [provider?.roles]);

  const selectDate = (
    category: ICategory,
    subcategory: ISubCategory,
    date: MaterialUiPickersDate
  ) => {
    const days = daysDiff(date);
    const formattedDate = moment(date).format() as string;

    setSelectedDate(formattedDate);
    setExtraDays(days);

    MixpanelWrapperInstance.trackChange({
      customEventName: EventNames.CardsChangeCardSequenceDate,
      name: 'Sequence date selector',
      type: ChangeElementTypes.MATERIAL_DATE_PICKER,
      value: formattedDate,
      inputData: {
        extraDays,
        parentCategoryId: category.id,
        parentCategoryTitle: category.title,
        categoryId: subcategory.id,
        categoryTitle: subcategory.title,
      },
    });
  };

  const columnHeader = [
    {
      id: 1,
      label: '',
    },
    {
      id: 2,
      label: '',
    },
    {
      id: 3,
      label: 'Schedule Order for Posting',
    },
  ];

  const getCategories = async () => {
    setLoading(true);
    const response: ICategory[] | null = await CardsService.getCategories(patient.uuid);
    setLoading(false);

    if (!response) {
      return Toast.show('error', `Error loading categories, please try again later!`);
    }

    setCategories(
      response?.filter((category: ICategory) => category.title?.includes(OFFICIAL_CONTENT_CATEGORY))
    );
    setCurriculumCategory(
      response?.filter((category: ICategory) =>
        category.title?.includes(OFFICIAL_CONTENT_CURRICULUM_CATEGORY)
      )[0]
    );
  };

  const isExpandableCategory = (category: ICategory) => {
    return category.sub_categories.length > 0;
  };

  const isExpandableSubCategory = (subCategory: ISubCategory) => {
    return subCategory.bcards.length > 0;
  };

  const onYes = async (
    onClose: () => void,
    id: string,
    _cards: ICard[],
    subCategoryInfo: ISubCategoryInfo
  ) => {
    const response = await CardsService.postCardSequence(
      id,
      patient.uuid,
      selectedDate,
      subCategoryInfo.forced
    );

    if (!response) {
      return Toast.show('error', 'Error posting card sequence, please try again.');
    }

    MixpanelWrapperInstance.trackClick({
      customEventName: EventNames.CardsClickPostCardSequence,
      name: 'Yes, Schedule this Card Sequence',
      type: ClickElementTypes.BUTTON,
      source: POST_CARD_SEQUENCE_SOURCE,
      inputData: {
        subCategoryInfo,
        selectedDate,
      },
    });

    const successMessage =
      subCategoryInfo.cards.length > 1
        ? `The ${subCategoryInfo.cards.length} cards in sequence "${subCategoryInfo.subCategoryTitle}" have`
        : `The card in sequence "${subCategoryInfo.subCategoryTitle}" has`;

    const startingDate = ` starting ${moment(selectedDate).format(Constants.MM_DD_YYYY_FORMAT)}`;

    Toast.show(
      'success',
      `${successMessage} been scheduled to post to ${patient.first_name} ${patient.last_name}${startingDate}.`
    );

    getCategories();

    onClose();
  };

  const onNo = async (category: ICategory, subcategory: ISubCategory, onClose: () => void) => {
    MixpanelWrapperInstance.trackClick({
      customEventName: EventNames.CardsClickPostCardSequence,
      name: 'Cancel',
      type: ClickElementTypes.BUTTON,
      source: POST_CARD_SEQUENCE_SOURCE,
      inputData: {
        forced,
        parentCategoryId: category.id,
        categoryId: subcategory.id,
      },
    });

    onClose();
  };

  const handleMessage = (date: string, title: string) => {
    return `<b>${title}</b>, it's scheduled for <b>${moment(date).format(
      Constants.MM_DD_YYYY_FORMAT
    )}</b>.<br/><br/>
     Would you like to post card sequence either way? Remember <b>${patient.first_name} ${
      patient.last_name
    }</b> will receive it twice.`;
  };

  const handlePostCardSequence = (category: ICategory, subcategory: ISubCategory) => {
    const subCategoryId = subcategory.id;
    const subCategoryTitle = subcategory.title;
    const cards = subcategory.bcards.filter((c: ICard) => c.checked);
    const schedule = subcategory?.schedule;

    const confirmationMessage =
      cards.length > 1
        ? `Do you want to schedule these <b>${cards.length}</b> cards,`
        : `Do you want to schedule this card,`;

    const startingDate = ` starting <b>${moment(selectedDate).format(
      Constants.MM_DD_YYYY_FORMAT
    )}</b>`;

    let message = '';

    if (schedule?.locked) {
      forced = true;
      message = handleMessage(schedule.scheduled_for_member_at, subCategoryTitle);
    } else
      message = `${confirmationMessage} in the sequence <b>${subCategoryTitle}</b><br/><br/>To post to <b>${patient.first_name} ${patient.last_name}</b>${startingDate}?`;

    confirmationDispatch({
      type: ActionTypes.SHOW,
      payload: {
        onYes,
        onNo: (onClose: () => void) => {
          return onNo(category, subcategory, onClose);
        },
        id: subCategoryId,
        cards,
        subCategoryTitle,
        forced,
        yesText: 'Yes, Schedule this Card Sequence',
        noText: 'Cancel',
        message,
      },
    });
  };

  const handleClickSubcategory = (parentCategory: ICategory, subcategory: ISubCategory) => {
    const subId = subcategory.id;

    setOpenSubCategory((prevState: IOpenItem) => ({
      open: prevState.open === subId ? null : subId,
    }));

    MixpanelWrapperInstance.trackClick({
      customEventName: EventNames.CardsClickCardSubcategory,
      name: subcategory.title,
      type: ClickElementTypes.ACCORDION,
      inputData: {
        parentCategoryId: parentCategory.id,
        categoryId: subcategory.id,
      },
    });

    // Restoring now as date
    setSelectedDate(moment().format());

    setExtraDays(0);
  };

  const renderSubCategoryItem = (category: ICategory, subCategory: ISubCategory) => (
    <div key={`subCategory-${subCategory.id}`}>
      {!isExpandableSubCategory(subCategory) ? (
        <ListEmptySubCategory>
          <ListItemIcon>
            <Icon icon='remove' size={25} />
          </ListItemIcon>
          <ListItemText primary={subCategory.title} />
        </ListEmptySubCategory>
      ) : (
        <ListItemButton onClick={() => handleClickSubcategory(category, subCategory)}>
          <ListSubCategoryIcon>
            <ListItemIcon>
              <Icon icon='add' size={25} />
            </ListItemIcon>
          </ListSubCategoryIcon>
          <ListItemText primary={subCategory.title} />
          {openSubCategory.open === subCategory.id ? (
            <Icon icon='expandLess' size={25} />
          ) : (
            <Icon icon='expandMore' size={25} />
          )}
        </ListItemButton>
      )}
    </div>
  );

  const renderSubCategoryChildren = (category: ICategory, subcategory: ISubCategory) => (
    <Collapse
      component='li'
      in={openSubCategory.open === subcategory.id}
      timeout='auto'
      unmountOnExit>
      <Divider />
      <List component='div'>
        <Section>
          <InlineRecord>
            {columnHeader.map((header) => (
              <InlineRecordItem
                key={`header-${header.id}`}
                customWidth='auto'
                customPaddingLeft='9em'>
                <Text>{header.label}</Text>
              </InlineRecordItem>
            ))}
          </InlineRecord>
          {subcategory.bcards
            ? subcategory.bcards
                .filter((c: ICard) => c.checked)
                .map((card: ICard, index) => {
                  return (
                    <Content key={`card-item-${card.id}`}>
                      <CardItem card={card} extraDays={extraDays} />
                      {index === 0 ? (
                        <CardRecord>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                              autoOk
                              disablePast
                              disableToolbar
                              value={selectedDate}
                              format={Constants.MM_DD_YYYY_FORMAT}
                              onChange={(date: MaterialUiPickersDate) => {
                                return selectDate(category, subcategory, date);
                              }}
                              minDate={moment().format()}
                              variant='inline'
                              keyboardIcon={<Icon icon='schedule' color='teal' />}
                              inputProps={{
                                style: {
                                  color: Theme.teal,
                                  fontWeight: 'bold',
                                },
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </CardRecord>
                      ) : (
                        <CardRecord backgroundColor='white' />
                      )}
                    </Content>
                  );
                })
            : null}
          <Footer>
            <ConfirmButton onClick={() => handlePostCardSequence(category, subcategory)}>
              Post Card Sequence
            </ConfirmButton>
          </Footer>
        </Section>
      </List>
    </Collapse>
  );

  const handleClickCategory = (id: number) => {
    setOpenCategory((prevState: IOpenItem) => ({
      open: prevState.open === id ? null : id,
    }));
  };

  const startCurriculumOnYes = async (
    onClose: () => void,
    categoryId: string,
    _subCategory: ISubCategory[],
    categoryInfo: ICategoryInfo
  ) => {
    const response = await CardsService.startCurriculum(categoryId, patient.uuid);

    if (!response) {
      return Toast.show('error', 'Error starting curriculum sequence, please try again.');
    }

    MixpanelWrapperInstance.trackClick({
      customEventName: EventNames.CardsClickStartCurriculum,
      name: 'Yes, Schedule Curriculum',
      type: ClickElementTypes.BUTTON,
      source: START_CURRICULUM_SOURCE,
      inputData: {
        categoryId,
        categoryInfo,
      },
    });

    Toast.show(
      'success',
      `The curriculum has been scheduled to post to ${patient.first_name} ${patient.last_name} starting today.`
    );

    getCategories();

    onClose();
  };

  const startCurriculumOnNo = async (onClose: () => void) => {
    MixpanelWrapperInstance.trackClick({
      customEventName: EventNames.CardsClickStartCurriculum,
      name: 'Cancel',
      type: ClickElementTypes.BUTTON,
      source: START_CURRICULUM_SOURCE,
    });
    onClose();
  };

  const printSubCategories = (category: ICategory) => {
    let str = '';
    let days = 0;
    category.sub_categories.forEach((sub) => {
      str += `<br/><b>${sub.title}</b> will post from <b>${moment()
        .add(days, 'days')
        .format(Constants.MMM_DAY_FORMAT)}</b> through <b>${moment()
        .add(days + 6, 'days')
        .format(Constants.MMM_DAY_FORMAT)}</b>`;
      days += 7;
    });

    return str;
  };

  const handleStartCurriculum = (category: ICategory) => {
    confirmationDispatch({
      type: ActionTypes.SHOW,
      payload: {
        onYes: startCurriculumOnYes,
        onNo: startCurriculumOnNo,
        id: category.id,
        category,
        yesText: 'Yes, Schedule Curriculum',
        noText: 'Cancel',
        message: `Do you want to schedule these <b>${
          category.sub_categories.length
        } modules to post subsequently in the next ${
          category.sub_categories.length
        } weeks</b>,<br/><br/> to <b>${patient.first_name} ${patient.last_name}</b>?<br/>
        ${printSubCategories(category)}
        `,
      },
    });
  };

  const renderCategoryItem = (category: ICategory) => (
    <ListItemButton onClick={() => handleClickCategory(category.id)}>
      <ListItemIcon>
        {isExpandableCategory(category) ? (
          <Icon icon='add' size={25} />
        ) : (
          <Icon icon='remove' size={25} />
        )}
      </ListItemIcon>
      <ListItemText primary={category.title} />
      {isExpandableCategory(category) &&
        openCategory.open === category.id &&
        isAllowed &&
        featuresState?.[EFeatures.ProviderShowPcc] &&
        category.title.includes(OFFICIAL_CONTENT_CURRICULUM_CATEGORY) &&
        (!curriculumCategory?.schedule ||
        (curriculumCategory?.schedule && !curriculumCategory?.schedule.locked) ? (
          <ConfirmButton onClick={() => handleStartCurriculum(category)}>
            Start Curriculum - All {category.sub_categories.length} Modules
          </ConfirmButton>
        ) : (
          <MSGText title={`Started by ${curriculumCategory.schedule.provider_fullname}`}>
            <ListItemText
              primary={
                <Typography variant='body1'>
                  Member was started on Curriculum on{' '}
                  {moment(curriculumCategory.schedule.posted_by_provider_at).format(
                    Constants.MMM_DAY_FORMAT
                  )}
                </Typography>
              }
            />
          </MSGText>
        ))}
      {isExpandableCategory(category) &&
        (openCategory.open === category.id ? (
          <Icon icon='expandLess' size={25} />
        ) : (
          <Icon icon='expandMore' size={25} />
        ))}
    </ListItemButton>
  );

  const renderCategoryChildren = (category: ICategory) => {
    const isIn = openCategory.open === category.id;

    if (isIn) {
      MixpanelWrapperInstance.trackClick({
        customEventName: EventNames.CardsClickCardCategory,
        name: category.title,
        type: ClickElementTypes.BUTTON,
        inputData: {
          categoryId: category.id,
        },
      });
    }

    return (
      <Collapse key={category.id} component='li' in={isIn} timeout='auto' unmountOnExit>
        <Divider />
        <List>
          {category.sub_categories
            ? category.sub_categories
                .filter((sub: ISubCategory) => sub.title?.includes(OFFICIAL_CONTENT_SUBCATEGORY))
                .map((subcategory: ISubCategory) => {
                  return (
                    <div key={`sub-category-item-${subcategory.id}`}>
                      {renderSubCategoryItem(category, subcategory)}
                      {renderSubCategoryChildren(category, subcategory)}
                    </div>
                  );
                })
            : null}
        </List>
      </Collapse>
    );
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <ListCard>
          <Text>Select a card sequence to post to member:</Text>
          <List component='nav'>
            {categories
              ? categories.map((category: ICategory) => (
                  <div key={`category-${category.id}`}>
                    {renderCategoryItem(category)}
                    {renderCategoryChildren(category)}
                  </div>
                ))
              : null}
          </List>
        </ListCard>
      )}
    </>
  );
};

export default PostCardSequence;
