import { Box, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';
import { Button } from 'components/elements';
import Toast from 'components/toast';
import MembersClient from 'node-api/members/MembersClient';
import { FC, useContext, useState } from 'react';
import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { EventNames } from 'services/mixpanel/provider-app-mixpanel-events';
import { ClickableElement, SelectableElement } from 'services/mixpanel/shared-mixpanel-service';
import { PatientContext } from 'state/contexts/patient';
import { WeightData } from 'state/reducers/weights';
import { getDateTimeFromString } from 'utils/dateHelpers';
import { Graphs } from '../constants';
import './weight-bulk-delete.css';

type Props = {
  onUpdateWeights: () => void;
  weights: WeightData[];
};

const columns: GridColDef[] = [
  { field: 'value', headerName: 'Weight', width: 80 },
  {
    field: 'date',
    headerName: 'Date',
    width: 170,
    valueFormatter: (param) => getDateTimeFromString(param.value),
  },
];

const WeightBulkDelete: FC<Props> = ({ onUpdateWeights, weights }) => {
  const [weightsToDelete, setWeightToDelete] = useState<number[]>([]);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const { patientState } = useContext(PatientContext);

  const patient = patientState.current;

  const handleCellClickEvent: GridEventListener<'cellClick'> = ({ row }) => {
    const foundIdIndex = weightsToDelete.findIndex((id) => id === row.id);
    if (foundIdIndex !== -1) {
      const currentIds = [...weightsToDelete];
      currentIds.splice(foundIdIndex, 1);

      setWeightToDelete(currentIds);
      return;
    }

    MixpanelWrapperInstance.track(EventNames.MemberProfileBulkDeleteWeightsSelectWeight, {
      targetType: SelectableElement.CHECKBOX,
      targetLocation: Graphs.Weights,
      memberId: patient?.id,
      weight: row.id,
    });

    setWeightToDelete([...weightsToDelete, row.id]);
  };

  const handleDeleteWeights = async () => {
    if (!patient) {
      return;
    }

    if (weightsToDelete.length === 0) {
      Toast.show('info', 'You have to select at least one weight to delete');
      return;
    }

    setIsDeleting(true);
    const deletePromises = [];
    const membersServiceClient = new MembersClient();
    for (const recordId of weightsToDelete) {
      const promise = membersServiceClient.deleteWeightInAllPlatforms(Number(patient.id), {
        healthRecordId: recordId,
        memberUuid: patient.uuid,
      });

      deletePromises.push(promise);
    }

    try {
      await Promise.all(deletePromises);
    } catch (err) {
      console.error('handleDeleteWeights::error: ', err);
    }

    MixpanelWrapperInstance.track(EventNames.MemberProfileBulkDeleteWeightsDeleteWeights, {
      targetLabel: 'delete selected weights',
      targetType: ClickableElement.BUTTON,
      targetLocation: Graphs.Weights,
      memberId: patient.id,
      weights: weightsToDelete,
    });

    onUpdateWeights();
    setIsDeleting(false);
  };

  return (
    <Stack className='weight-container'>
      <Typography id='weight-delete-title' component='h2'>
        {`${patient?.first_name} ${patient?.last_name} weights`}
      </Typography>

      {weights.length > 0 && (
        <DataGrid
          className='weight-bulk-grid'
          onCellClick={handleCellClickEvent}
          onRowSelectionModelChange={(model) => {
            setWeightToDelete(model as number[]);
          }}
          rows={weights}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 50 },
            },
          }}
          checkboxSelection
        />
      )}

      <Box className='weight-bottom-container'>
        <Button onClick={handleDeleteWeights} disabled={isDeleting} submitting={isDeleting}>
          Delete Selected Weights
        </Button>
      </Box>
    </Stack>
  );
};

export default WeightBulkDelete;
