/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Button,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {
  MixpanelFeedbackClick,
  MixpanelFeedbackComment,
} from 'components/feature-feedback/MixpanelFeedback';
import WeightPredictionService, {
  MemberInfo,
  PlanPredictionResponse,
} from 'node-api/PredictionWeight';
import { HealthRecordsClient } from 'node-api/health-records/HealthRecordsClient';
import { useContext, useState } from 'react';
import { PatientContext } from 'state/contexts/patient';
import { getAge } from 'utils/dateHelpers';
import './weight-prediction.css';

type DefaultTextFieldProps = {
  onChange: (value: string) => void;
  fieldValue: string;
  fieldLabel: string;
  helperText: string;
};

const DefaultTextField = ({
  onChange,
  fieldValue,
  fieldLabel,
  helperText,
}: DefaultTextFieldProps) => {
  return (
    <TextField
      label={fieldLabel}
      helperText={helperText}
      variant='outlined'
      size='small'
      value={fieldValue}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      InputLabelProps={{
        sx: {
          fontSize: '14px',
          '&.MuiOutlinedInput-notchedOutline': { fontSize: '14px' },
        },
      }}
    />
  );
};

const WeightPrediction = () => {
  const [dryLeanMass, setDryLeanMass] = useState<string>('0');
  const [ir, setIr] = useState<string>('0');
  const [ldl, setLdl] = useState<string>('0');
  const [hscrp, setHscrp] = useState<string>('0');
  const [hA1c, setHA1c] = useState<string>('0');
  const [hdl, setHdl] = useState<string>('0');
  const [predictedRee, setPredictedRee] = useState<string>('0');
  const [measured, setMeasured] = useState<string>('0');
  const [loadingData, setLoadingData] = useState(false);
  const [prediction, setPrediction] = useState<PlanPredictionResponse | null>(null);
  const [noBodyCompData, setNoBodyCompData] = useState(false);
  const [bodyFatMass, setBodyFatMass] = useState('0');
  const [skeletalMuscleMass, setSkeletalMuscleMass] = useState('0');
  const [percentageBodyFat, setPercentageBodyFat] = useState('0');

  const { patientState } = useContext(PatientContext);

  const handleCalculateWeight = async () => {
    const patientData = patientState.current;
    if (!patientData) {
      return;
    }

    setLoadingData(true);
    let memberBodyFatMass: string;
    let memberSkeletalMuscleMass: string;
    let memberPercentageBodyFat: string;
    if (noBodyCompData) {
      memberBodyFatMass = bodyFatMass;
      memberSkeletalMuscleMass = skeletalMuscleMass;
      memberPercentageBodyFat = percentageBodyFat;
    } else {
      const memberReports = await new HealthRecordsClient().getMemberBodyCompositionReport(
        patientData.id.toString()
      );

      if (!memberReports || memberReports.length === 0) {
        console.warn(`I don't have report data :( `);
        setNoBodyCompData(true);
        setLoadingData(false);
        return;
      }

      const content = memberReports[memberReports.length - 1].recordedContent;
      memberBodyFatMass = content.BFM;
      memberSkeletalMuscleMass = content.SMM;
      memberPercentageBodyFat = content.PBF;
    }

    const params: MemberInfo = {
      startingWeight: patientData.initial_weight?.toString() || '0',
      Age: getAge(patientData.birthdate),
      height: patientData.feet?.toString() || '0',
      BFM: memberBodyFatMass,
      SMM: memberSkeletalMuscleMass,
      DLM: dryLeanMass,
      PBF: memberPercentageBodyFat,
      gender: patientData.gender.toLowerCase(),
      homa_ir: ir,
      ldl,
      hdl,
      hscrp,
      hA1c,
      duration: '12', // By default are 12 month
      measured_ree: measured,
      predicted_ree: predictedRee,
    };

    const weightData = await new WeightPredictionService().calculate(params);
    setPrediction(weightData);
    setLoadingData(false);
  };

  return (
    <Stack
      direction='column'
      spacing={2}
      sx={{
        margin: '10px',
      }}>
      <Typography
        component='h5'
        variant='h5'
        marginBottom={3}
        sx={{ alignSelf: 'flex-start', color: 'black' }}>
        Weight Loss Calculator
      </Typography>

      <Stack
        spacing={2}
        direction='row'
        sx={{
          alignSelf: 'start',
          width: '100%',
          justifyContent: 'space-evenly',
        }}>
        <DefaultTextField
          fieldLabel='Dry Lean Mass (DLM)'
          helperText='lbs'
          fieldValue={dryLeanMass}
          onChange={setDryLeanMass}
        />
        <DefaultTextField
          fieldLabel='Homeostatic model assessment'
          helperText='HOMA-IR'
          fieldValue={ir}
          onChange={setIr}
        />
        <DefaultTextField
          fieldLabel='Low Density Lipoproteins (LDL)'
          helperText='g/ml'
          fieldValue={ldl}
          onChange={setLdl}
        />
      </Stack>

      <Stack
        spacing={2}
        direction='row'
        sx={{
          alignSelf: 'start',
          width: '100%',
          justifyContent: 'space-evenly',
        }}>
        <DefaultTextField
          fieldLabel='hs-CRP'
          helperText='mg/l'
          fieldValue={hscrp}
          onChange={setHscrp}
        />
        <DefaultTextField
          fieldLabel='Hemoglobin A1C'
          helperText='%'
          fieldValue={hA1c}
          onChange={setHA1c}
        />
        <DefaultTextField
          fieldLabel='High Density Lipoproteins (HDL)'
          helperText='g/ml'
          fieldValue={hdl}
          onChange={setHdl}
        />
      </Stack>

      <Stack
        spacing={2}
        direction='row'
        sx={{
          alignSelf: 'center',
          justifyContent: 'space-evenly',
          width: '100%',
        }}>
        <DefaultTextField
          fieldLabel='Measured Ree'
          helperText='kcal/day'
          fieldValue={measured}
          onChange={setMeasured}
        />
        <DefaultTextField
          fieldLabel='Predicted Ree'
          helperText='kcal/day'
          fieldValue={predictedRee}
          onChange={setPredictedRee}
        />
      </Stack>

      {noBodyCompData && (
        <>
          <Stack>
            <Typography>
              We could not find enough data in our system, please fill the following fields and
              click on Calculate again
            </Typography>
          </Stack>
          <Stack
            spacing={2}
            direction='row'
            sx={{ alignSelf: 'center', justifyContent: 'space-evenly', width: '100%' }}>
            <DefaultTextField
              fieldLabel='Body Fat Mass'
              helperText='lbs'
              fieldValue={bodyFatMass}
              onChange={setBodyFatMass}
            />
            <DefaultTextField
              fieldLabel='Skeletal Muscle Mass'
              helperText='lbs'
              fieldValue={skeletalMuscleMass}
              onChange={setSkeletalMuscleMass}
            />
            <DefaultTextField
              fieldLabel='Percentage Body Fat'
              helperText='%'
              fieldValue={percentageBodyFat}
              onChange={setPercentageBodyFat}
            />
          </Stack>
        </>
      )}

      <Stack sx={{ width: '100%' }}>
        <Button
          sx={{ alignSelf: 'center' }}
          className='primary'
          variant='contained'
          onClick={handleCalculateWeight}
          disabled={loadingData}>
          Calculate {loadingData ? <CircularProgress size={24} /> : ''}
        </Button>
      </Stack>

      {prediction && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Program</b>
                </TableCell>
                <TableCell>
                  <b>Weight loss prediction at 12 months</b>
                </TableCell>
                <TableCell>
                  <b>80% Confidence Interval</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>STEPWISE</TableCell>
                <TableCell>
                  {`${Math.abs(parseFloat(prediction.data.stepwise.prediction))}%`}
                </TableCell>
                <TableCell>{`${Math.abs(
                  parseFloat(prediction.data.stepwise.upper_bound)
                )}% to ${Math.abs(parseFloat(prediction.data.stepwise.lower_bound))}%`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>VLCD</TableCell>
                <TableCell>{`${Math.abs(parseFloat(prediction.data.vlcd.prediction))}%`}</TableCell>
                <TableCell>{`${Math.abs(
                  parseFloat(prediction.data.vlcd.upper_bound)
                )}% to ${Math.abs(parseFloat(prediction.data.vlcd.lower_bound))}%`}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {prediction && (
        <Stack className='feedback-container'>
          <Typography>Was this prediction useful?</Typography>
          <MixpanelFeedbackClick label='feedback' source='rating' />
          <MixpanelFeedbackComment label='feedback-comment' source='rating' />
        </Stack>
      )}
    </Stack>
  );
};

export default WeightPrediction;
