import { CancelToken } from 'axios';
import { MEMBERS_WEB_APP_URL, NODE_API } from 'config';
import { SharedEnaraNodeClient } from 'node-api/common/SharedEnaraNodeClient';
import {
  AcuityAppointmentTag,
  AppointmentTypeWithProviders,
  DailyAvailabilityV2,
  GetAppointmentTypesForCareTeamResponse,
  GetAppointmentTypesForClinicResponse,
  MonthlyAvailabilityV2,
  Specialty,
} from './Appointments.types';

interface CreateAppointmentPayload {
  appointmentTypeId: number;
  calendarId: number;
  datetime: string;
  userId: number;
}

export type ScheduledMember = {
  address: string;
  avatar: string;
  birthDate: string;
  city: string;
  email: string;
  firstName: string;
  gender: string;
  id: number;
  lastName: string;
  phone: string;
  state: string;
  timezone: string;
  uuid: string;
  zipCode: string;
};

export type ScheduledAppointment = {
  appointmentType: string;
  confirmationPage: string;
  endAt: string;
  isGroupClass: boolean;
  id: number;
  location: string;
  providerId: number;
  startAt: string;
  status: string;
  userId: number;
  user: ScheduledMember;
};

export interface TimeSlot {
  time: string;
  slotsAvailable: number;
}

export interface Day {
  date: string;
}

export default class AppointmentsClient extends SharedEnaraNodeClient {
  constructor() {
    super(NODE_API.schedule);
  }

  static getMembersSchedulingLink(hash: string | undefined): string {
    if (!hash) {
      return '';
    }

    return `${MEMBERS_WEB_APP_URL}/scheduling-link?p=${hash}`;
  }

  async appointmentTypes(
    { clinicId }: { clinicId: number },
    cancelToken?: CancelToken
  ): Promise<GetAppointmentTypesForClinicResponse> {
    const { data } = await this.axiosClient.get(
      `schedule/v1/clinics/${clinicId}/appointment-types`,
      {
        cancelToken,
      }
    );
    return data;
  }

  createAppointment(payload: CreateAppointmentPayload) {
    return this.axiosClient.post(`/schedule/v1/providers/create-appointment`, {
      ...payload,
      tag: AcuityAppointmentTag.ProviderAppScheduleTab,
    });
  }

  /**
   * @deprecated Only use until v2 allows to call with `calendarId`
   * @param month
   * @param appointmentTypeID
   * @param calendarID
   * @returns
   */
  getDays(month: string, appointmentTypeID: number, calendarID: number) {
    return this.axiosClient.get<{ dates: Day[] }>(
      `/schedule/v1/available-dates?appointmentTypeId=${appointmentTypeID}&calendarId=${calendarID}&month=${month}`
    );
  }

  /**
   * New version of getMonthlyAvailability, including `allowed` and `issues` for each day.
   * Currently, it does not allow to pass `calendarId` as a parameter, so it only works for main provider.
   * @param memberId (mandatory) Member we are getting the availability for
   * @param month (mandatory) Month we are getting the availability for (format: YYYY-MM)
   * @param appointmentTypeId (optional) Type of appointment we are getting the availability for
   * @param calendarId (optional) Calendar we are getting the availability for (uses care-team when not provided)
   * @param specialty (optional) Specialty we are getting the availability for (uses when appointmentTypeId is not provided)
   * @returns Allowed days and issues for each day
   */
  async getMonthlyAvailability({
    memberId,
    month,
    appointmentTypeId,
    calendarId,
    specialty,
  }: {
    memberId: number;
    month: string;
    appointmentTypeId?: number;
    calendarId?: number;
    specialty?: Specialty;
  }): Promise<MonthlyAvailabilityV2> {
    const monthlyAvailabilityResponse = await this.axiosClient.get<
      { success: boolean } & MonthlyAvailabilityV2
    >(`/v2/members/${memberId}/appointments/follow-up/available-dates`, {
      params: { appointmentTypeId, calendarId, specialty, month },
    });

    if (monthlyAvailabilityResponse && monthlyAvailabilityResponse.data) {
      const { success, ...rest } = monthlyAvailabilityResponse.data;
      if (success) {
        return rest;
      }
    }

    throw new Error('Failed to get monthly availability');
  }

  getTimes(date: string, appointmentTypeID: number, calendarID: number) {
    return this.axiosClient.get<{ times: TimeSlot[] }>(
      `/schedule/v1/available-times?appointmentTypeId=${appointmentTypeID}&calendarId=${calendarID}&date=${date}`
    );
  }

  /**
   * New version of getDailyAvailability, including `allowed` and `issues` for each day.
   * Currently, it does not allow to pass `calendarId` as a parameter, so it only works for main provider.
   * @param memberId (mandatory) Member we are getting the availability for
   * @param date (mandatory) Date we are getting the availability for (format: YYYY-MM-DD)
   * @param appointmentTypeId (optional) Type of appointment we are getting the availability for
   * @param calendarId (optional) Calendar we are getting the availability for (uses care-team when not provided)
   * @param locationType (optional) Where the appointment takes place (IN / OL, uses when appointmentTypeId is not provided)
   * @param specialty (optional) Specialty we are getting the availability for (uses when appointmentTypeId is not provided)
   * @returns Allowed slots and issues for each one of them
   */
  async getDailyAvailability({
    memberId,
    date,
    appointmentTypeId,
    calendarId,
    locationType,
    specialty,
  }: {
    memberId: number;
    date: string;
    appointmentTypeId?: number;
    calendarId?: number;
    locationType?: string;
    specialty?: Specialty;
  }): Promise<DailyAvailabilityV2> {
    const dailyAvailabilityResponse = await this.axiosClient.get<DailyAvailabilityV2>(
      `/v2/members/${memberId}/appointments/follow-up/available-times`,
      {
        params: { appointmentTypeId, calendarId, date, locationType, specialty },
      }
    );

    if (!dailyAvailabilityResponse?.data) {
      throw new Error('Failed to get daily availability');
    }

    return dailyAvailabilityResponse.data;
  }

  async getAppointmentTypesForCareTeam({
    memberId,
  }: {
    memberId: number;
  }): Promise<GetAppointmentTypesForCareTeamResponse> {
    const { data } = await this.axiosClient.get(
      `/schedule/v1/members/${memberId}/appointment-types`
    );
    return data;
  }

  async getScheduledAppointmentsForProvider(
    providerId: number,
    { endDate, startDate }: { endDate: Date; startDate: Date }
  ) {
    const { data } = await this.axiosClient.get<{
      providerId: number;
      appointments: ScheduledAppointment[];
    }>(`/v2/providers/${providerId}/appointments`, {
      params: {
        endDate: endDate?.toISOString()?.slice(0, 10),
        startDate: startDate?.toISOString()?.slice(0, 10),
      },
    });
    return data;
  }

  async getAvailableGroupClassAppointments(
    memberUUID: string
  ): Promise<AppointmentTypeWithProviders[]> {
    const { data } = await this.axiosClient.get<{
      success: boolean;
      groupClassAppointments: AppointmentTypeWithProviders[];
    }>(`/v3/providers/${memberUUID}/appointments/group-classes`);

    return data.groupClassAppointments;
  }

  async sendAppointmentReminderViaSms({
    memberUuid,
    providerUuid,
  }: {
    memberUuid: string;
    providerUuid: string;
  }) {
    const { data } = await this.axiosClient.post<
      | {
          status: true;
        }
      | { status: false; reason: string }
    >(`/v1/providers/${providerUuid}/members/${memberUuid}/appointments/next/reminders/sms`);
    return data;
  }
}
